import React, { useContext, useState } from "react";
import { format } from "date-fns";
import HotelsPopup from "./HotelsPopup";
import { Button, TextField } from "@mui/material";
import "./MatchingTable.css";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import axios from "axios";
import CreateRawShowModal from "./CreateRawShowModel";
import UploadLocatorsSection from "../uploadControllers/UploadLocatorSection";

const headerOfTableLeads = [
  { label: "Index", value: "#" },
  { label: "ShowName", value: "Show Name" },
  { label: "companyCount", value: "Companies count" },
  { label: "CreatedAt", value: "Created At" },
  { label: "matchingDetails", value: "Matching Details" },
];
const headerOfTablepRicing = [
  { label: "Index", value: "#" },
  { label: "ShowName", value: "Show Name" },
  { label: "IsExpired", value: "Is Expired" },
  { label: "LastModified", value: "Last Modified" },
  { label: "MatchingDetails", value: "Matching Details" },
];

const HistoryMatchingTable = ({ rawLeads, rawPricings }) => {
  const [pricingSearchQuery, setPricingSearchQuery] = useState("");
  const [leadSearchQuery, setLeadSearchQuery] = useState("");

  const formatDate = (dateString) => {
    if (dateString) {
      return format(new Date(dateString), "MMM dd, yyyy h:mm a");
    }
  };

  const filteredLeads = rawLeads?.filter((item) =>
    item.showName.toLowerCase().includes(leadSearchQuery.toLowerCase())
  );

  // Filter rawPricings based on the pricingSearchQuery
  const filteredPricings = rawPricings?.filter((item) =>
    item.showName.toLowerCase().includes(pricingSearchQuery.toLowerCase())
  );

  return (
    <div className="History-MatchingTable-container">
      <div>
        <h2 style={{ marginBottom: "1rem" }}>Matched Raw Leads</h2>
        <TextField
          label="Search Leads"
          variant="outlined"
          size="small"
          fullWidth
          style={{ marginBottom: "1rem" }}
          onChange={(e) => setLeadSearchQuery(e.target.value)}
        />
        
        <div className="raw-leads-table-contain">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              overflow: "auto",
              boxSizing: "border-box",
            }}
          >
            <thead>
              <tr>
                {headerOfTableLeads.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid #ddd",
                      padding: "14px 18px",
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                  >
                    {header.value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredLeads?.map((item, index) => (
                <tr key={item._id}>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "50px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "100px",
                    }}
                  >
                    {item.showName}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "100px",
                    }}
                  >
                    {item.companyCount}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "100px",
                    }}
                  >
                    {formatDate(item.createdAt)}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "200px",
                      verticalAlign: "top", // Align text to the top for better readability
                    }}
                  >
                    {item.matchingDetails?.length > 0 ? (
                      <ul
                        style={{
                          paddingLeft: "20px",
                          margin: 0,
                          listStyleType: "circle",
                        }}
                      >
                        {item.matchingDetails.map((detail, detailIndex) => (
                          <li
                            key={detailIndex}
                            style={{
                              marginBottom: "10px",
                              padding: "10px",
                              backgroundColor: "#f9f9f9",
                              border: "1px solid #e0e0e0",
                              borderRadius: "5px",
                              boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div>
                              <strong style={{ color: "#333" }}>Show:</strong>{" "}
                              <span style={{ color: "#555" }}>
                                {detail.show || "N/A"}
                              </span>
                            </div>
                            <div>
                              <strong style={{ color: "#333" }}>
                                Pricing:
                              </strong>{" "}
                              <span style={{ color: "#555" }}>
                                {detail.pricing || "N/A"}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <span style={{ color: "#999", fontStyle: "italic" }}>
                        No Matching Details
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2 style={{ marginBottom: "1rem" }}>Matched Raw Pricings</h2>
        <TextField
          label="Search Pricings"
          variant="outlined"
          size="small"
          fullWidth
          style={{ marginBottom: "1rem" }}
          onChange={(e) => setPricingSearchQuery(e.target.value)}
        />
        <div className="raw-pricings-table-contain">
          <div className="table-wrapper">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  {headerOfTablepRicing.map((header, index) => (
                    <th
                      key={index}
                      style={{
                        border: "1px solid #ddd",
                        padding: "14px 18px",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {header.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredPricings?.map((item, index) => (
                  <tr key={item._id}>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "50px",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "100px",
                      }}
                    >
                      {item.showName}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "100px",
                      }}
                    >
                      {item.isExpired ? "yes" : "No"}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "100px",
                      }}
                    >
                      {formatDate(item.lastModified)}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "200px",
                      }}
                    >
                      {item.matchingDetails?.length > 0 ? (
                        <ul
                          style={{
                            paddingLeft: "20px",
                            margin: 0,
                            listStyleType: "circle",
                          }}
                        >
                          {item.matchingDetails.map((detail, detailIndex) => (
                            <li
                              key={detailIndex}
                              style={{
                                marginBottom: "10px",
                                padding: "10px",
                                backgroundColor: "#f9f9f9",
                                border: "1px solid #e0e0e0",
                                borderRadius: "5px",
                                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <div>
                                <strong>Show:</strong> {detail.show || "N/A"}
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        "No Matching Details"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryMatchingTable;
