import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { format } from "date-fns"; // Importing the format function
import { baseUrl } from "../../api/Api";

const AgentReports = () => {
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const leadStatusList = [
    { label: "Total Leads", value: "totalLeads" },
    { label: "New", value: "New" },
    { label: "VM", value: "VM" },
    { label: "Callback", value: "Callback" },
    { label: "GK Blocked", value: "GK Blocked" },
    { label: "DM Rejected", value: "DM Rejected" },
    { label: "Do Not Call", value: "Do Not Call" },
    { label: "Email Only", value: "Email Only" },
    { label: "Email Follow-up", value: "Email Follow-up" },
    { label: "Waiting Approval", value: "Waiting Approval" },
    { label: "Prospect", value: "Prospect" },
    { label: "Flagged", value: "Flagged" },
    { label: "N/A", value: "N/A" },
    { label: "Closed Won", value: "Closed Won" },
    { label: "Closed Lost", value: "Closed Lost" },
  ];

  const formatDecimalToTime = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setLoading(true); // Start loading before the API call
        const response = await axios.get(
          `${baseUrl}/api/sales-manager/get-agent-reports/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data.result; // Assuming the response contains a `result` field
        console.log(data);

        // Modify data to include missing status counts
        const updatedReports = data.map((report) => {
          // Initialize missing statuses with 0
          const updatedStatusCounts = { ...report.leadStatusCounts };
          leadStatusList.forEach(({ value }) => {
            if (!(value in updatedStatusCounts)) {
              updatedStatusCounts[value] = 0;
            }
          });

          const leadsCount = Object.values(updatedStatusCounts).reduce(
            (sum, count) => sum + count,
            0
          );

          // Format loggedinTime and loggedoutTime to human-readable format
          const formattedLoggedinTime = format(
            new Date(report.loggedinTime),
            "hh:mm a"
          );
          const formattedLoggedoutTime = format(
            new Date(report.loggedoutTime),
            "hh:mm a"
          );

          const formattedTotalHours = formatDecimalToTime(report.totalHours);
          const formattedBreakTime = formatDecimalToTime(report.breakTime);

          return {
            ...report,
            leadStatusCounts: updatedStatusCounts,
            formattedLoggedinTime,
            formattedLoggedoutTime,
            formattedTotalHours,
            formattedBreakTime,
            leadsCount,
          };
        });

        setReports(updatedReports);
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        console.error("Error fetching reports:", error);
        setError("Failed to fetch reports. Please try again.");
        setLoading(false); // Stop loading in case of error
      }
    };

    fetchReports(); // Call the function when component mounts or `id` or `token` changes
  }, [id, token]); // Dependencies: re-fetch when `id` or `token` changes

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <TableContainer sx={{ padding: 2, borderRadius: "8px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Date</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Name</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Login Time</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Logout Time</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Total Hours</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Break Time</strong>
              </TableCell>
              {/* Add columns for each lead status */}
              {leadStatusList.map((status) => (
                <TableCell key={status.value} sx={{ color: "wheat" }}>
                  <strong>{status.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.date}>
                <TableCell>{report.date}</TableCell>
                <TableCell>{report.userName}</TableCell>
                <TableCell>{report.formattedLoggedinTime}</TableCell>
                <TableCell>{report.formattedLoggedoutTime}</TableCell>
                <TableCell>{report.formattedTotalHours}</TableCell>
                <TableCell>{report.formattedBreakTime}</TableCell>
                {/* Render lead status counts */}
                {leadStatusList.map((status) => (
                  <TableCell key={status.value}>
                    {report.leadStatusCounts[status.value]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AgentReports;
