import React, { useContext, useEffect, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "./SupportLanding.css";
import SalesSupportPage from "../../components/salesSupportTeam/SalesSupportPage";
import AllRequestsPage from "../../components/salesSupportTeam/AllRequestsPage";
import EmailTeamPage from "../../components/salesSupportTeam/EmailTeamPage";
import { AuthContext } from "../../components/context/AuthContext";
import EmailTeamHistory from "../../components/salesSupportTeam/EmailTeamHistory";
import NewPricingEmailReq from "../../components/PricingEmailRequest/NewPricingEmailReq";
import PricingEmailRequest from "../../components/PricingEmailRequest/PricingEmailRequest";
import axios from "axios";
import { baseUrl } from "../../api/Api";

const SupportLanding = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLiveRequestsActive, setIsLiveRequestsActive] = useState(false);
  const [isEmailRequestsActive, setIsEmailRequestsActive] = useState(false);
  const [isEmailHistoryActive, setIsEmailHistoryActive] = useState(false);
  const [isRequestsHistoryActive, setIsRequestsHistoryActive] = useState(false);

  const [isSupportEmailsActive, setIsSupportEmailsActive] = useState(false);
  const [isSupportEmailsHistoryActive, setIsSupportEmailsHistoryActive] =
    useState(false);
  const [emailRequests, setEmailRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const { role, token } = useContext(AuthContext);
  console.log(role);
  const toggleSidebar = () => setIsOpen(!isOpen);

  // useEffect(() => {
  //   const fetchEmailRequests = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${baseUrl}/api/email-team/history-email-requests`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       // Filter out duplicates based on requestId
  //       const uniqueRequests = [];
  //       const requestIds = new Set();

  //       response.data.forEach((request) => {
  //         if (!requestIds.has(request._id)) {
  //           requestIds.add(request._id);
  //           uniqueRequests.push(request);
  //         }
  //       });

  //       // Sort by a timestamp or other property to ensure most recent requests are at the top
  //       const sortedRequests = uniqueRequests.sort(
  //         (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //       );
  //       setEmailRequests(sortedRequests);

  //       // setEmailRequests(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching live requests:", error);
  //     }
  //   };

  //   fetchEmailRequests();
  // }, []);

  const showLiveRequests = () => {
    setIsLiveRequestsActive(true);
    setIsRequestsHistoryActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(false);
    setIsSupportEmailsActive(false);
    setIsSupportEmailsHistoryActive(false);
  };

  const showEmailRequests = () => {
    setIsRequestsHistoryActive(false);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(true);
    setIsEmailHistoryActive(false);
    setIsSupportEmailsActive(false);
    setIsSupportEmailsHistoryActive(false);
  };

  const showRequestsHistory = () => {
    setIsRequestsHistoryActive(true);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(false);
    setIsSupportEmailsActive(false);
    setIsSupportEmailsHistoryActive(false);
  };
  const showEmailRequestsHistory = () => {
    setIsRequestsHistoryActive(false);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(true);
    setIsSupportEmailsActive(false);
    setIsSupportEmailsHistoryActive(false);
  };
  const showSupportEmailsRequests = () => {
    setIsRequestsHistoryActive(false);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(false);
    setIsSupportEmailsActive(true);
    setIsSupportEmailsHistoryActive(false);
  };

  const showSupportEmailsHistory = () => {
    setIsRequestsHistoryActive(false);
    setIsLiveRequestsActive(false);
    setIsEmailRequestsActive(false);
    setIsEmailHistoryActive(false);
    setIsSupportEmailsActive(false);
    setIsSupportEmailsHistoryActive(true);
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Sales Support View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              {role === "sales support" && (
                <button onClick={showLiveRequests} className="sidebar-btn">
                  Live Requests
                </button>
              )}
              {role === "sales support" && (
                <button
                  onClick={() => showSupportEmailsRequests()}
                  className="sidebar-btn"
                >
                  Email Request
                </button>
              )}
              {role === "sales support" && (
                <button
                  onClick={() => showSupportEmailsHistory()}
                  className="sidebar-btn"
                >
                  History Email Request
                </button>
              )}
              {role === "email team" && (
                <button onClick={showEmailRequests} className="sidebar-btn">
                  Email Requests
                </button>
              )}
              {role === "email team" && (
                <button
                  onClick={showEmailRequestsHistory}
                  className="sidebar-btn"
                >
                  History
                </button>
              )}
              {(role === "sales support manager" ||
                role === "email team manager") && (
                <button onClick={showRequestsHistory} className="sidebar-btn">
                  Requests History
                </button>
              )}
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>

        {role === "sales support" && isLiveRequestsActive && (
          <div className="table-container">
            <h2 className="table-title">Live Requests Page</h2>
            <SalesSupportPage />
          </div>
        )}
        {role === "email team" && isEmailRequestsActive && (
          <div className="table-container">
            <h2 className="table-title">Email Requests Page</h2>
            <EmailTeamPage />
          </div>
        )}

        {role === "email team" && isEmailHistoryActive && (
          <div className="table-container">
            <h2 className="table-title">Email History Page</h2>
            <EmailTeamHistory requests={emailRequests} isLoading={loading} />
          </div>
        )}

        {(role === "sales support manager" ||
          role === "email team manager" ||
          isRequestsHistoryActive) && (
          <div className="table-container">
            <h2 className="table-title">Requests History</h2>
            <AllRequestsPage />
          </div>
        )}

        {isSupportEmailsActive && (
          <div className="table-container">
            <h2 className="table-title">Live Requests Page</h2>
            <NewPricingEmailReq token={token} />
          </div>
        )}

        {isSupportEmailsHistoryActive && (
          <div className="table-container">
            <h2 className="table-title">Live Requests Page</h2>
            <PricingEmailRequest token={token} />
          </div>
        )}

        {/* {isEmailRequestsActive && (
          <div className="table-container">
            <h2 className="table-title">Email Requests Page</h2>
            <EmailTeamPage />
          </div>
        )} */}

        {/* {isRequestsHistoryActive && (
          <div className="table-container">
            <h2 className="table-title">Requests History</h2>
            <AllRequestsPage />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SupportLanding;
