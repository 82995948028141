import React, { useContext, useState } from "react";
import { format } from "date-fns";
import HotelsPopup from "./HotelsPopup";
import { Button, TextField } from "@mui/material";
import "./MatchingTable.css";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import axios from "axios";
import CreateRawShowModal from "./CreateRawShowModel";
import UploadLocatorsSection from "../uploadControllers/UploadLocatorSection";
import { useNavigate } from "react-router-dom";

const headerOfTableLeads = [
  { label: "Index", value: "#" },
  { label: "ShowName", value: "Show Name" },
  { label: "companyCount", value: "Companies count" },
  { label: "CreatedAt", value: "Created At" },
  // { label: "matchingDetails", value: "Matching Details" },
];
const headerOfTablepRicing = [
  { label: "Index", value: "#" },
  { label: "ShowName", value: "Show Name" },
  { label: "IsExpired", value: "Is Expired" },
  { label: "LastModified", value: "Last Modified" },
  { label: "details", value: "Detials" },
  { label: "", value: "" },
];

const GeneratedMatchingTable = ({
  rawLeads,
  rawPricings,
  fetchRawPricings,
  fetchRawLeads,
  activeRowIdInLead,
  activeRowIdInPricing,
  handleLeadRowClick,
  handlePricingRowClick,
}) => {
  const [isHotelPopupActive, setIsHotelPopupActive] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [viewUploadSection, setViewUploadSection] = useState(false);
  const [selectedRawLeadsId, setSelectedRawLeadsId] = useState(null);
  const [leadSearchQuery, setLeadSearchQuery] = useState("");
  const [pricingSearchQuery, setPricingSearchQuery] = useState("");
  const [openCreateRawShowModal, setOpenCreateRawShowModal] = useState(false);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (dateString) {
      return format(new Date(dateString), "MMM dd, yyyy h:mm a");
    }
  };

  const handleViewPricingData = (pricing) => {
    setSelectedPricing(pricing);
    setIsHotelPopupActive(true);
  };

  const handleCreateRawShowClick = () => {
    setOpenCreateRawShowModal(true); // Open the modal when button is clicked
  };

  const handleCloseCreateRawShowModal = () => {
    setOpenCreateRawShowModal(false); // Close the modal
  };

  const handleViewUploadSection = (showId) => {
    setSelectedRawLeadsId(showId);
    setViewUploadSection(true);
  };

  const handleClosePopup = () => {
    setIsHotelPopupActive(false);
  };

  const handleCloseUploadSection = () => {
    setViewUploadSection(false);
  };

  const handleDeletePricing = async (pricingId) => {
    if (window.confirm("Are you sure you want to delete this pricing?")) {
      try {
        const response = await axios.delete(
          `${baseUrl}/api/locator/delete-generated-raw-pricing/${pricingId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          alert("Pricing deleted successfully.");
          fetchRawPricings();
        } else {
          alert("Failed to delete pricing. Please try again.");
        }
      } catch (error) {
        console.error("Error deleting pricing:", error);
        alert("An error occurred while deleting the pricing.");
      }
    }
  };

  const handleViewDublicaedLeads = (show, showId) => {
    const fetchDublicatedLeadsOfTheShow = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/locator/duplicated-generated-raw-leads/${showId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);

        // Only navigate after the leads have been fetched

        const encodedShow = encodeURIComponent(show);
        navigate(`/leads-of-raw-show/${encodedShow}`, {
          state: {
            showLeads: response.data,
            show,
            showId,
            type: "dublicated",
            showType: "generated",
          },
        });
      } catch (error) {
        console.error(`Error fetching Leads of ${show}:`, error);
      }
    };

    fetchDublicatedLeadsOfTheShow();
  };

  const filteredLeads = rawLeads?.filter((item) =>
    item.showName.toLowerCase().includes(leadSearchQuery.toLowerCase())
  );

  // Filter rawPricings based on the pricingSearchQuery
  const filteredPricings = rawPricings?.filter((item) =>
    item.showName.toLowerCase().includes(pricingSearchQuery.toLowerCase())
  );

  return (
    <div className="MatchingTable-container">
      <div style={{ width: "50%" }}>
        <h2 style={{ marginBottom: "1rem" }}>Manually Generated Raw Leads</h2>
        <TextField
          label="Search Leads"
          variant="outlined"
          size="small"
          fullWidth
          style={{ marginBottom: "1rem" }}
          onChange={(e) => setLeadSearchQuery(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateRawShowClick}
        >
          Create Raw Show
        </Button>

        <div className="raw-leads-table-contain">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              overflow: "auto",
              boxSizing: "border-box",
            }}
          >
            <thead>
              <tr>
                {headerOfTableLeads.map((header, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid #ddd",
                      padding: "14px 18px",
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                  >
                    {header.value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredLeads?.map((item, index) => (
                <tr
                  key={item._id}
                  onClick={() => handleLeadRowClick(item._id)}
                  style={{
                    backgroundImage:
                      item._id === activeRowIdInLead
                        ? "linear-gradient(to right, #ded5e6, #ddd9d9, #fff)"
                        : "none",
                    cursor: "pointer",
                    border: "1px solid #ddd",
                  }}
                >
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "50px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "100px",
                    }}
                  >
                    {item.showName}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "100px",
                    }}
                  >
                    {item.companyCount}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "100px",
                    }}
                  >
                    {formatDate(item.createdAt)}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "17px 20px",
                      minWidth: "100px",
                    }}
                  >
                    <Button onClick={() => handleViewUploadSection(item._id)}>
                      Upload
                    </Button>

                    <Button
                      onClick={() =>
                        handleViewDublicaedLeads(item.showName, item._id)
                      }
                    >
                      Dublicates
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <h2 style={{ marginBottom: "1rem" }}>
          {" "}
          Manually Generated Raw Pricings
        </h2>
        <TextField
          label="Search Pricings"
          variant="outlined"
          size="small"
          fullWidth
          style={{ marginBottom: "1rem" }}
          onChange={(e) => setPricingSearchQuery(e.target.value)}
        />
        <div className="raw-pricings-table-contain">
          <div className="table-wrapper">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  {headerOfTablepRicing.map((header, index) => (
                    <th
                      key={index}
                      style={{
                        border: "1px solid #ddd",
                        padding: "14px 18px",
                        textAlign: "left",
                        textTransform: "capitalize",
                      }}
                    >
                      {header.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredPricings?.map((item, index) => (
                  <tr
                    key={item._id}
                    onClick={() => handlePricingRowClick(item._id)}
                    style={{
                      backgroundImage:
                        item._id === activeRowIdInPricing
                          ? "linear-gradient(to right, #ded5e6, #ddd9d9, #fff)"
                          : "none",
                      cursor: "pointer",
                      border: "1px solid #ddd",
                    }}
                  >
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "50px",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "100px",
                      }}
                    >
                      {item.showName}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "100px",
                      }}
                    >
                      {item.isExpired ? "yes" : "No"}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "100px",
                      }}
                    >
                      {formatDate(item.lastModified)}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "17px 20px",
                        minWidth: "100px",
                        display: "flex",
                      }}
                    >
                      <Button onClick={() => handleViewPricingData(item)}>
                        View
                      </Button>
                      <Button
                        onClick={() => handleDeletePricing(item._id)}
                        color="secondary"
                        style={{ marginLeft: "10px" }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {openCreateRawShowModal && (
        <CreateRawShowModal
          open={openCreateRawShowModal} // Control visibility
          onClose={handleCloseCreateRawShowModal} // Close function
          token={token} // Pass the token
        />
      )}

      {viewUploadSection && (
        <div className="modal-backdrop">
          <div className="upload-modal-content">
            <UploadLocatorsSection showId={selectedRawLeadsId} />
            <button onClick={handleCloseUploadSection}>Close</button>
          </div>
        </div>
      )}

      {isHotelPopupActive && selectedPricing && (
        <div className="modal-backdrop" onClick={handleClosePopup}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={handleClosePopup}>
              &times;
            </button>
            <HotelsPopup data={selectedPricing} showType="generated" />
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneratedMatchingTable;
