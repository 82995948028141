import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import "./Locator.css";
import cityState from "../../utils/Timezones";

import { AuthContext } from "../../components/context/AuthContext";
import LocatorTable from "../../components/Locator/LocatorTable";
import UploadLocatorsSection from "../../components/uploadControllers/UploadLocatorSection";
import MatchingTable from "../../components/Locator/MatchingTable";
import LocatorPopup from "../../components/Locator/LocatorPopup";
import HotelsPopup from "../../components/Locator/HotelsPopup";
import { baseUrl } from "../../api/Api";
import ReportsTable from "../../components/Locator/ReportsTable";
import JuniorPricingTable from "../../components/pricingTeam/pricingJunior/JuniorPricingTable";
import AddPricingTable from "../../components/pricingTeam/pricingSenior/AddPricingTable";
import LocatedTable from "../../components/Locator/LocatedTable";
import UsersReportsTable from "../../components/Locator/UsersReportsTable";
import HistoryMatchingTable from "../../components/Locator/HistoryMatchingTable";
import GeneratedMatchingTable from "../../components/Locator/GeneratedMatchingTable";

const Locator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLocatorTableActive, setIsLocatorTableActive] = useState(true);
  const [isMatchTableActive, setIsMatchTableActive] = useState(false);
  const [isGeneratedMatchTableActive, setIsGeneratedMatchTableActive] =
    useState(false);
  const [isMatchingHistoryActive, setIsMatchingHistoryActive] = useState(false);
  const [isUploadActive, setIsUploadActive] = useState(false);
  const [isReportsActive, setIsReportsActive] = useState(false);
  const [isRawPricingActive, setIsRawPricingActive] = useState(false);
  const [isPricingActive, setIsPricingActive] = useState(false);
  const [isUsersTableActive, setIsUsersTableActive] = useState(false);
  const [isLocatedTableActive, setIsLocatedTableActive] = useState(false);
  const [allShows, setAllShows] = useState(null);
  const [rawGeneratedPricing, setRawGeneratedPricing] = useState(null);

  const [locatorData, setLocatorData] = useState(null);
  const [rawLeads, setRawLeads] = useState(null);
  const [generatedRawLeads, setGeneratedRawLeads] = useState(null);

  const [matchedRawLeads, setMatchedRawLeads] = useState(null);
  const [rawPricings, setRawPricings] = useState(null);
  const [generatedRawPricings, setGeneratedRawPricings] = useState(null);

  const [matchedRawPricings, setMatchedRawPricings] = useState(null);

  const [locatedShows, setLocatedShows] = useState(null);
  //////////////////////////////////////////////////////////////////////////////////////
  const [activeRowIdInLead, setActiveRowIdInLead] = useState(null);
  const [activeShowName, setActiveShowName] = useState(null);

  const [activeRowIdInPricing, setActiveRowIdInPricing] = useState(null);
  const [selectedLocatedShow, setSelectedLocatedShow] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [formDataLocator, setFormDataLocator] = useState({
    edition: "",
    Show: "",
    "Show Url": "",
    "Next Dates": "",
    "End Date": "",
    Duration: "",
    "Convention Center / Host Hotel": "",
    Venue: "",
    "City, Country": "",
    description: "",
    similarShows: [],
  });

  const { role, token } = useContext(AuthContext);

  const [requestQueue, setRequestQueue] = useState([]);
  const [isProcessingQueue, setIsProcessingQueue] = useState(false);
  const [processIds, setProcessIds] = useState([]);
  const [processStatuses, setProcessStatuses] = useState([]);

  // console.log(role);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const fetchLocatorData = async () => {
    try {
      console.log("Fetching staging data...");
      const response = await axios.get(`${baseUrl}/api/locator/locator-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLocatorData(response.data);
      console.log("Staging data fetched:", response.data);
    } catch (error) {
      console.error("Error fetching staging data:", error);
    }
  };

  const fetchRawLeads = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/locator/get-raw-leads`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRawLeads(response.data);
      console.log("Raw Leads", response.data);
    } catch (error) {
      console.log("Error fetching raw leads", error);
    }
  };

  const fetchGeneratedRawLeads = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-generated-raw-leads`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGeneratedRawLeads(response.data);
      console.log("Raw Leads", response.data);
    } catch (error) {
      console.log("Error fetching raw leads", error);
    }
  };

  const fetchMatchedRawLeads = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-matched-raw-leads`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMatchedRawLeads(response.data);
      console.log("Raw Leads", response.data);
    } catch (error) {
      console.log("Error fetching raw leads", error);
    }
  };

  const fetchRawPricings = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-raw-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRawPricings(response.data);
      console.log("Raw Pricings", response.data);
    } catch (error) {
      console.log("Error fetching raw pricings", error);
    }
  };

  const fetchGeneratedRawPricings = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-generated-raw-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setGeneratedRawPricings(response.data);
      console.log("Raw Pricings", response.data);
    } catch (error) {
      console.log("Error fetching raw pricings", error);
    }
  };

  const fetchMatchedRawPricings = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/get-matched-raw-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMatchedRawPricings(response.data);
      console.log("Raw Pricings", response.data);
    } catch (error) {
      console.log("Error fetching raw pricings", error);
    }
  };

  const fetchLocatedShows = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/locator/located-shows-data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLocatedShows(response.data.data);
      console.log("Raw Pricings", response.data);
    } catch (error) {
      console.log("Error fetching raw pricings", error);
    }
  };

  useEffect(() => {
    fetchLocatedShows();
    fetchLocatorData();
  }, []);

  useEffect(() => {
    const fetchMatchTableData = async () => {
      await fetchRawLeads();
      await fetchRawPricings();
    };
    if (isMatchTableActive) fetchMatchTableData();
  }, [isMatchTableActive]);

  useEffect(() => {
    const fetchGeneratedMatchTableData = async () => {
      await fetchGeneratedRawLeads();
      await fetchGeneratedRawPricings();
    };
    if (isGeneratedMatchTableActive) fetchGeneratedMatchTableData();
  }, [isGeneratedMatchTableActive]);

  useEffect(() => {
    const fetchMatchHistoryTableData = async () => {
      await fetchMatchedRawLeads();
      await fetchMatchedRawPricings();
    };
    if (isMatchingHistoryActive) fetchMatchHistoryTableData();
  }, [isMatchingHistoryActive]);

  useEffect(() => {
    if (isPricingActive) {
      fetchAllShows();
    }
  }, [isPricingActive]);

  useEffect(() => {
    if (isRawPricingActive) {
      fetchManuallyGeneratedRawPricing();
    }
  }, [isRawPricingActive]);

  ///////////////////////////////////////////////////////////////////

  useEffect(() => {
    const processQueueAsync = async () => {
      if (requestQueue.length > 0 && !isProcessingQueue) {
        setIsProcessingQueue(true);
        await processQueue();
      }
    };
    processQueueAsync();
  }, [requestQueue]);

  const processQueue = async () => {
    setIsProcessingQueue(true);

    if (requestQueue.length === 0) {
      setIsProcessingQueue(false);
      return;
    }

    let url;
    if (isMatchTableActive) {
      url = `${baseUrl}/api/locator/match-pricing-show/${activeRowIdInLead}/${activeRowIdInPricing}`;
    }

    if (isGeneratedMatchTableActive) {
      url = `${baseUrl}/api/locator/match-generated-pricing-show/${activeRowIdInLead}/${activeRowIdInPricing}`;
    }

    // Set the status of the first request in the queue to "Pending"
    const currentRequest = requestQueue[0];
    setProcessStatuses((prevStatuses) =>
      prevStatuses.map((req) =>
        req.id === currentRequest.id ? { ...req, status: "Pending" } : req
      )
    );

    try {
      const {
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing,
        resolve,
      } = currentRequest;
      await axios.post(url, formDataLocator, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Update the status to "Completed" after successful request
      const updatedStatus = { show: formDataLocator.Show, status: "Completed" };
      setProcessStatuses((prev) =>
        prev.map((req) => (req.id === currentRequest.id ? updatedStatus : req))
      );

      // Resolve the promise for this request
      resolve(updatedStatus);
    } catch (error) {
      console.error("Request error:", error);
      setProcessStatuses((prev) =>
        prev.map((req) =>
          req.id === currentRequest.id ? { ...req, status: "Failed" } : req
        )
      );
      // Handle rejection in case of error
      currentRequest.reject(error);
    }

    // Remove the completed request from the queue and process the next one
    setRequestQueue((prevQueue) => prevQueue.slice(1));
    setIsProcessingQueue(false);
  };

  const addRequestToQueue = (
    formDataLocator,
    activeRowIdInLead,
    activeRowIdInPricing
  ) => {
    return new Promise((resolve, reject) => {
      const newRequest = {
        id: Date.now(), // Unique ID for each request
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing,
        resolve,
        reject,
        status: "Waiting",
      };

      // Add the request to the queue and initialize status as "Waiting"
      setRequestQueue((prevQueue) => [...prevQueue, newRequest]);
      setProcessStatuses((prevStatuses) => [
        ...prevStatuses,
        { id: newRequest.id, show: formDataLocator.Show, status: "Waiting" },
      ]);
    });
  };

  const fetchAllShows = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/pricing/all-shows`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setAllShows(response.data.data);
    } catch (error) {
      console.error("Error fetching junior staging data:", error);
    }
  };

  const fetchManuallyGeneratedRawPricing = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/pricing/manually-generated-pricings`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRawGeneratedPricing(response.data.data);
    } catch (error) {
      console.error("Error fetching junior staging data:", error);
    }
  };

  //////////////////////////////////////////////////

  const showLocatorTable = () => {
    setIsLocatorTableActive(true);
    setIsUploadActive(false);
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };

  const showUsersTable = () => {
    setIsLocatorTableActive(false);
    setIsUploadActive(false);
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(true);
    setIsMatchingHistoryActive(false);
  };

  const showMatchTable = () => {
    setIsMatchTableActive(true);
    setIsGeneratedMatchTableActive(false);
    setIsLocatorTableActive(false);
    setIsUploadActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };

  const showGeneratedMatchTable = () => {
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(true);
    setIsLocatorTableActive(false);
    setIsUploadActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };

  const showHistoryMatchTable = () => {
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsMatchingHistoryActive(true);
    setIsLocatorTableActive(false);
    setIsUploadActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
  };

  const showCreateRawPricingTable = () => {
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsLocatorTableActive(false);
    setIsUploadActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(true);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };

  const showCreatePricing = () => {
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsLocatorTableActive(false);
    setIsUploadActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(true);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };

  const showReportsTable = () => {
    setIsUploadActive(false);
    setIsLocatorTableActive(false);
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsReportsActive(true);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };

  const showLocatedTable = () => {
    setIsUploadActive(false);
    setIsLocatorTableActive(false);
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(true);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };

  const showUploadControllers = () => {
    setIsUploadActive(true);
    setIsLocatorTableActive(false);
    setIsMatchTableActive(false);
    setIsGeneratedMatchTableActive(false);
    setIsReportsActive(false);
    setIsRawPricingActive(false);
    setIsPricingActive(false);
    setIsLocatedTableActive(false);
    setIsUsersTableActive(false);
    setIsMatchingHistoryActive(false);
  };
  ///////////////////////////////////////////////////////////////////////////////
  const handleLeadRowClick = (id, showName) => {
    console.log("LeadRow:", id);
    console.log("Show name", showName);
    setActiveRowIdInLead(id);
    setActiveShowName(showName);
  };

  const handlePricingRowClick = (id) => {
    console.log("PricingRow:", id);
    setActiveRowIdInPricing(id);
  };

  const handleLocatedShowClick = (locatedData) => {
    setSelectedLocatedShow(locatedData);

    const duration = calculateDuration(
      locatedData.startDate,
      locatedData.endDate
    );
    console.log(locatedData.city);

    const edition = extractYear(locatedData.startDate);
    const formattedNextDates = formatDateToISO(locatedData.startDate);
    const formattedEndDate = formatDateToISO(locatedData.endDate);

    const locatedCityName = locatedData.city.split(",")[0].trim().toLowerCase();

    // Find the matching city from cityState by comparing only the city part before the comma
    const matchedCity = cityState.find((city) => {
      const cityName = city.split(",")[0].trim().toLowerCase();
      return cityName === locatedCityName;
    });

    // If a match is found, use it, otherwise, set to "N/A" or leave empty
    const cityCountry = matchedCity ? matchedCity : locatedData.city;

    setFormDataLocator({
      ...formDataLocator,
      edition: edition,
      Show: locatedData.showName,
      "Next Dates": formattedNextDates,
      "End Date": formattedEndDate,
      Duration: duration,
      Venue: locatedData.venue,
      "City, Country": cityCountry,
      description: locatedData.description,
      similarShows: locatedData.similarShows,
    });
  };

  const extractYear = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return year.toString();
  };

  const formatDateToISO = (dateStr) => {
    const [day, month, year] = dateStr.split("/").map(Number);
    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}`;
  };

  const handleMatchButtonClick = () => {
    if (activeRowIdInLead !== null && activeRowIdInPricing !== null) {
      let selectedPricing;
      if (isMatchTableActive) {
        selectedPricing = rawPricings.find(
          (pricing) => pricing._id === activeRowIdInPricing
        );
      }
      if (isGeneratedMatchTableActive) {
        selectedPricing = generatedRawPricings.find(
          (pricing) => pricing._id === activeRowIdInPricing
        );
      }

      // const showName = selectedPricing.showName;

      // const [show, nextDates, endDate] = parseShowName(showName);

      // const duration = calculateDuration(nextDates, endDate);
      // const formattedNextDates = formatDateToISO(nextDates);
      // const formattedEndDate = formatDateToISO(endDate);

      // setFormDataLocator({
      //   ...formDataLocator,
      //   Show: showName,
      //   "Next Dates": formattedNextDates,
      //   "End Date": formattedEndDate,
      //   Duration: duration,
      // });

      setIsPopupVisible(true);
    }
  };

  const parseShowName = (showName) => {
    // Split the string by commas
    const parts = showName.split(",").map((part) => part.trim());

    // Extract the relevant parts
    const show = parts[0] || "";
    const nextDates = parts[1] || "";
    const endDate = parts[2] || "";

    return [show, nextDates, endDate];
  };

  const convertToMMDDYYYY = (dateStr) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateStr.split("/").map(Number);

    // Return the date in MM/DD/YYYY format
    return `${month}/${day}/${year}`;
  };

  const convertToDDMMYYYY = (dateStr) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateStr.split("/").map(Number);

    // Return the date in MM/DD/YYYY format
    return `${day}/${month}/${year}`;
  };

  const calculateDuration = (startDateStr, endDateStr) => {
    // Convert date strings from dd/mm/yyyy to mm/dd/yyyy
    const startDate = new Date(convertToDDMMYYYY(startDateStr));
    const endDate = new Date(convertToDDMMYYYY(endDateStr));

    // Calculate the duration in days
    const durationInDays = Math.ceil(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );

    return durationInDays;
  };

  const handleClosePopup = () => {
    setFormDataLocator({
      edition: "",
      Show: "",
      "Show Url": "",
      "Next Dates": "",
      "End Date": "",
      Duration: "",
      "Convention Center / Host Hotel": "",
      Venue: "",
      "City, Country": "",
      description: "",
      similarShows: [],
    });
    setIsPopupVisible(false);
  };

  const handleChangedata = (e) => {
    const { id, name, value } = e.target;
    setFormDataLocator((prevData) => ({
      ...prevData,
      [id]: value,
      [name]: value,
    }));
  };

  const handleButtonClick = async (e) => {
    handleClosePopup();

    // Add request to queue and handle it
    try {
      await addRequestToQueue(
        formDataLocator,
        activeRowIdInLead,
        activeRowIdInPricing
      );
      // Reset form data after processing
      setFormDataLocator({
        edition: "",
        Show: "",
        "Show Url": "",
        "Next Dates": "",
        "End Date": "",
        Duration: "",
        "Convention Center / Host Hotel": "",
        Venue: "",
        "City, Country": "",
        description: "",
        similarShows: [],
      });
    } catch (error) {
      console.error("Error processing request:", error);
    }
  };
  console.log(processStatuses);
  const handleEditLocatedData = async (updatedShow) => {
    console.log(updatedShow);

    try {
      // Assuming updatedShow contains the necessary data and showId
      const response = await axios.put(
        `http://localhost:4800/api/locator/update-located/${updatedShow._id}`,
        updatedShow, // Sending the updated show data in the body of the request
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token to the headers
          },
        }
      );

      if (response.status === 200) {
        console.log("Show updated successfully:", response.data);
        // Optionally, update the state with the updated show data
        setLocatedShows((prevShows) =>
          prevShows.map((show) =>
            show.showId === updatedShow.showId ? updatedShow : show
          )
        );
      }
    } catch (error) {
      console.error("Error updating the show:", error);
      // Handle error appropriately (e.g., show a toast message, error UI, etc.)
    }
  };

  return (
    <div className="pricing-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Locator View</h2>
      </div>
      <div className="main-pricing-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showLocatorTable} className="sidebar-btn">
                Locator Table
              </button>
              <button onClick={showMatchTable} className="sidebar-btn">
                Match pricing show
              </button>
              <button onClick={showGeneratedMatchTable} className="sidebar-btn">
                Generated Match pricing show
              </button>
              <button onClick={showHistoryMatchTable} className="sidebar-btn">
                History Matching
              </button>
              <button
                onClick={showCreateRawPricingTable}
                className="sidebar-btn"
              >
                Create Raw Pricing
              </button>
              <button onClick={showCreatePricing} className="sidebar-btn">
                Create pricing
              </button>
              <button onClick={showLocatedTable} className="sidebar-btn">
                Located Table
              </button>
              {role === "locator manager" && (
                <button onClick={showUsersTable} className="sidebar-btn">
                  Users Reports
                </button>
              )}
              <button onClick={showReportsTable} className="sidebar-btn">
                Reports
              </button>

              {/* <button onClick={showUploadControllers} className="sidebar-btn">
                Upload Shows
              </button> */}
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {(isMatchTableActive || isGeneratedMatchTableActive) && (
          <div className="request-status-container">
            {processStatuses.map((req) => (
              <div key={req.id} className={`request-status ${req.status}`}>
                <p>Show: {req.show}</p>
                <p>Status: {req.status}</p>
              </div>
            ))}
          </div>
        )}

        {isLocatorTableActive && (
          <div className="table-container">
            <h2 className="table-title">Locator Table</h2>
            <LocatorTable
              data={locatorData}
              fetchData={fetchLocatorData}
              role={role}
            />
          </div>
        )}

        {isLocatedTableActive && (
          <div className="table-container">
            <h2 className="table-title">Locator Table</h2>
            <LocatedTable
              locatedShows={locatedShows}
              handleLocatedShowClick={handleLocatedShowClick}
              role={role}
              onEdit={handleEditLocatedData}
            />
          </div>
        )}

        {isRawPricingActive && (
          <>
            <JuniorPricingTable
              data={rawGeneratedPricing}
              fetchData={fetchManuallyGeneratedRawPricing}
              role={role}
            />
          </>
        )}

        {isPricingActive && (
          <>
            <AddPricingTable
              data={allShows}
              fetchData={fetchAllShows}
              role={role}
            />
          </>
        )}
        {isUploadActive && (
          <div className="table-container">
            <h2 className="table-title">Upload Data</h2>
            <UploadLocatorsSection />
          </div>
        )}

        {isReportsActive && (
          <div className="table-container">
            <h2 className="table-title">Reports</h2>
            <ReportsTable />
          </div>
        )}

        {isUsersTableActive && (
          <div className="table-container">
            <h2 className="table-title">Reports</h2>
            <UsersReportsTable />
          </div>
        )}

        {isMatchingHistoryActive && (
          <div className="table-container">
            <h2 className="table-title">Matching History</h2>
            <HistoryMatchingTable
              rawLeads={matchedRawLeads}
              rawPricings={matchedRawPricings}
            />
          </div>
        )}

        {isMatchTableActive && (
          <div className="match-locator-container">
            <h2 className="match-locator-header">Match Data</h2>
            <div className="match-tables-contain">
              <div className="match-btn-container">
                <button
                  className="match-button"
                  onClick={handleMatchButtonClick}
                >
                  Match
                </button>
              </div>
              <div className="match-tables-section">
                <MatchingTable
                  rawLeads={rawLeads}
                  rawPricings={rawPricings}
                  locatedShows={locatedShows}
                  fetchRawLeads={fetchRawLeads}
                  fetchRawPricings={fetchRawPricings}
                  activeRowIdInLead={activeRowIdInLead}
                  activeRowIdInPricing={activeRowIdInPricing}
                  handleLeadRowClick={handleLeadRowClick}
                  handlePricingRowClick={handlePricingRowClick}
                />
              </div>
            </div>
          </div>
        )}

        {isGeneratedMatchTableActive && (
          <div className="match-locator-container">
            <h2 className="match-locator-header">Match Data</h2>
            <div className="match-tables-contain">
              <div className="match-btn-container">
                <button
                  className="match-button"
                  onClick={handleMatchButtonClick}
                >
                  Match
                </button>
              </div>
              <div className="match-tables-section">
                <GeneratedMatchingTable
                  rawLeads={generatedRawLeads}
                  rawPricings={generatedRawPricings}
                  fetchRawLeads={fetchGeneratedRawLeads}
                  fetchRawPricings={fetchGeneratedRawPricings}
                  activeRowIdInLead={activeRowIdInLead}
                  activeRowIdInPricing={activeRowIdInPricing}
                  handleLeadRowClick={handleLeadRowClick}
                  handlePricingRowClick={handlePricingRowClick}
                />
              </div>
            </div>
          </div>
        )}

        {isPopupVisible && (
          <LocatorPopup
            handleClosePopup={handleClosePopup}
            activeShowName={activeShowName}
            formDataLocator={formDataLocator}
            handleChangedata={handleChangedata}
            handleButtonClick={handleButtonClick}
            locatedShows={locatedShows}
            handleLocatedShowClick={handleLocatedShowClick}
          />
        )}
      </div>
    </div>
  );
};

export default Locator;
