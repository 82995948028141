const cityState = [
  "Jersey City, NJ",
  "Washington, DC",
  "New Haven, CT",
  "Seattle, WA",
  "Portland, ME",
  "Boise, ID",
  "Stockton, CA",
  "New York City, NY",
  "Los Angeles, CA",
  "Philadelphia, PA",
  "Cleveland, OH",
  "Springfield, IL",
  "Duluth, MN",
  "Gary, IN",
  "Chicago Suburbs, IL",
  "Baton Rouge, LA",
  "Gulfport, MS",
  "Albany, GA",
  "Western MI",
  "Akron, OH",
  "Naples, FL",
  "Eastern NC",
  "Tacoma, WA",
  "Huntsville, AL",
  "Western KY",
  "Southwest, VA",
  "Maryland suburbs of DC, MD",
  "Delaware, DE",
  "Denver, CO",
  "West Virginia",
  "Miami, FL",
  "Wyoming, WY",
  "Nebraska, NE",
  "Peoria, IL",
  "Southern, CA",
  "Chicago, IL",
  "Detroit, MI",
  "St. Louis, MO",
  "Syracuse, NY",
  "Wichita, KS",
  "Indianapolis, IN",
  "Shreveport, LA",
  "Cedar Rapids, IA",
  "Central, MN",
  "Central, FL",
  "Los Angeles, CA",
  "Akron, OH",
  "Northern, IL",
  "Montgomery, AL",
  "Greensboro, NC",
  "Lafayette, LA",
  "New York, NY",
  "Concord, MA",
  "Gainesville, FL",
  "Olympia, WA",
  "Ogden, UT",
  "Daytona Beach, FL",
  "Rhode Island, RI",
  "Nebraska, NE",
  "Atlanta, GA",
  "Oklahoma City, OK",
  "Montana MT",
  "Orlando, FL",
  "San Jose, CA",
  "Salzburg, Austria",
  "Baltimore, MD",
  "Pittsburgh, PA",
  "Springfield, MA",
  "Milwaukee, WI",
  "San Francisco, CA",
  "Springfield, MO",
  "Toledo, OH",
  "Chattanooga, TN",
  "Santa Monica, CA",
  "Seattle Suburbs, WA",
  "Lynchburg, VA",
  "Utah",
  "Cleveland, OH",
  "Baltimore, MD (Cell Phones)",
  "Metro Atlanta, GA",
  "Bridgeport, CT",
  "Macon, GA",
  "Ft. Smith, AR",
  "Little Rock, AR",
  "Louisville, KY",
  "Portland, OR",
  "New Orleans, LA",
  "New Mexico, NM",
  "Rochester, MN",
  "Worcester, MA",
  "Spokane, WA",
  "Oakland, CA",
  "Cincinnati, OH",
  "Des Moines, IA",
  "Long Island, NY",
  "Lansing, MI",
  "Albany, NY",
  "Tucson, AZ",
  "Redding, CA",
  "Omaha, NE",
  "Tulsa, OK",
  "Roanoke, VA",
  "Eugene, OR",
  "Jersey City, NJ",
  "Fresno, CA",
  "West Palm Beach, FL",
  "Long Beach, CA",
  "Toledo, OH",
  "Scranton, PA",
  "Arlington, VA",
  "Columbia, MO",
  "South Bend, IN",
  "Lawton, OK",
  "Rochester, NY",
  "Warren, MI",
  "Jackson, MS",
  "Phoenix, AZ",
  "Manchester, NH",
  "Rapid City, SD",
  "Ashland, KY",
  "Binghamton, NY",
  "Madison, WI",
  "Trenton, NJ",
  "Allentown, PA",
  "Minneapolis, MN",
  "Nashville, TN",
  "Grand Rapids, MI",
  "Boston, MA",
  "Belleville, IL",
  "San Diego, CA",
  "Hutchinson, KS",
  "Glendale, AZ",
  "Pasadena, CA",
  "Naperville, IL",
  "Huntington, NY",
  "St. Charles, MO",
  "Mason City, IA",
  "New York City, NY",
  "San Mateo, CA",
  "St. Paul, MN",
  "Sedalia, MO",
  "Bakersfield, CA",
  "Tupelo, MS",
  "Baltimore, MD",
  "Atlanta, GA",
  "Charleston, WV",
  "Fargo, ND",
  "Las Vegas, NV",
  "Alexandria, VA",
  "Charlotte, NC",
  "Augusta, GA",
  "North Coast, CA",
  "Cicero, IL",
  "Sioux City, IA",
  "Orange County, CA",
  "Central, WI",
  "Buffalo, NY",
  "Harrisburg, PA",
  "New York City, NY",
  "Colorado Springs, CO",
  "Tampa Bay, FL",
  "Jackson, TN",
  "Central NJ",
  "Southeastern MI",
  "Southeastern OH",
  "Los Angeles, CA",
  "Hampton Roads, VA",
  "Southern, CA",
  "Athens, GA",
  "Central, IN",
  "Hattiesburg, MS",
  "Atlanta, GA",
  "Treasure Coast, FL",
  "Chicago, IL",
  "Brockton, MA",
  "Reno, NV",
  "Joliet, IL",
  "Boston, MA",
  "Topeka, KS",
  "Miami, FL",
  "Salt Lake City, UT",
  "Vermont, VT",
  "Columbia, SC",
  "Richmond, VA",
  "Central Coast, CA",
  "Hawaii",
  "Flint, MI",
  "Terre Haute, IN",
  "Tampa, FL",
  "Erie, PA",
  "Rockford, IL",
  "Kansas City, MO",
  "San Fernando Valley, CA",
  "Asheville, NC",
  "Del Rio, TX",
  "Monterey, CA",
  "Houston, TX",
  "Charleston, SC",
  "Hudson Valley, NY",
  "Northern IL",
  "New Jersey, NJ",
  "Pensacola, FL",
  "Southern, NJ",
  "Boston, MA",
  "San Diego, CA",
  "Central, CT",
  "Northern, NJ",
  "Tyler, TX",
  "Jacksonville, FL",
  "Southern Ontario, Canada",
  "Central, NJ",
  "Southeastern, NC",
  "Savannah, GA",
  "Kansas City, KS",
  "Westchester, NY",
  "El Paso, TX",
  "Sacramento, CA",
  "New York City, NY",
  "Tulsa, OK",
  "Raleigh, NC",
  "Northern, AZ",
  "New York City, NY",
  "Huntsville, TX",
  "Dayton, OH",
  "North TX",
  "Sarasota, FL",
  "Orange County, CA",
  "Minneapolis, MN",
  "Fort Lauderdale, FL",
  "Ft. Collins, CO",
  "Portland, OR",
  "Dallas, TX",
  "Northern, NJ",
  "Charlotte, NC",
  "Southeastern, LA",
  "Northern, MI",
  "New York City, NY",
  "Los Angeles, CA",
  "Ohio",
  "Denver, CO",
  "Western, PA",
  "Eastern, TN",
  "Phoenix, AZ",
  "East Bay, CA",
  "British Columbia, Canada",
  "Eastern Ontario, Canada",
  "Nevada, NV",
  "Toronto, Ontario, Canada",
  "Anaheim, CA",
  "Hamilton, ON",
  "Greenville, SC",
  "Charleston, SC",
  "Lowell, MA",
  "Montreal, QC",
  "Gatineau, QC",
  "Houston, TX",
  "Laval, QC",
  "San Bernardino, CA",
  "San Francisco, CA",
  "Malden, MA",
  "Montreal, QC",
  "Unspecified",
  "London, ON",
  "Green Bay, WI",
  "Minneapolis, MN",
  "Riverside, CA",
  "Chicago, IL",
  "Lexington, KY",
  "New Brunswick, CA",
  "Nova Scotia, CA",
  "Nova Scotia, CA",
  "Anguilla",
  "Antigua and Barbuda",
  "US Virgin Islands",
  "Dominica",
  "Dominican Republic",
  "Dominican Republic",
  "Dominican Republic",
  "British Virgin Islands",
  "Bermuda",
  "Barbados",
  "Bahamas",
  "Grenada",
  "Trinidad and Tobago",
  "Montserrat",
  "Puerto Rico",
  "Puerto Rico",
  "Turks and Caicos Islands",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Sint Maarten",
  "Manitoba, CA",
  "Saskatchewan, CA",
  "Manitoba, CA",
  "Saskatchewan, CA",
  "Birmingham, AL",
  "San Antonio, TX",
  "Dallas, TX",
  "Springfield, IL",
  "Duluth, MN",
  "Chicago, IL",
  "Baton Rouge, LA",
  "Biloxi, MS",
  "Mobile, AL",
  "Killeen, TX",
  "Huntsville, AL",
  "Waukesha, WI",
  "Houston, TX",
  "Grand Island, NE",
  "Peoria, IL",
  "Chicago, IL",
  "St. Louis, MO",
  "Wichita, KS",
  "Shreveport, LA",
  "Cedar Rapids, IA",
  "St. Cloud, MN",
  "Abilene, TX",
  "Aurora, IL",
  "Montgomery, AL",
  "Lafayette, LA",
  "Corpus Christi, TX",
  "Omaha, NE",
  "Oklahoma City, OK",
  "Beaumont, TX",
  "Milwaukee, WI",
  "Milwaukee, WI",
  "Little Rock, AR",
  "Dallas, TX",
  "Houston, TX",
  "Springdale, AR",
  "Little Rock, AR",
  "New Orleans, LA",
  "Rochester, MN",
  "Austin, TX",
  "Des Moines, IA",
  "Tulsa, OK",
  "Davenport, IA",
  "Columbia, MO",
  "Enid, OK",
  "Jackson, MS",
  "Madison, WI",
  "Minneapolis, MN",
  "Nashville, TN",
  "Carbondale, IL",
  "Hutchinson, KS",
  "Naperville, IL",
  "St. Louis, MO",
  "Ames, IA",
  "St. Paul, MN",
  "Kirksville, MO",
  "Cape Girardeau, MO",
  "Bismarck, ND",
  "Chicago, IL",
  "Sioux City, IA",
  "Houston, TX",
  "Eau Claire, WI",
  "Jackson, TN",
  "Rockford, IL",
  "Topeka, KS",
  "Lubbock, TX",
  "Rockford, IL",
  "Kansas City, MO",
  "Fort Worth, TX",
  "Memphis, TN",
  "Tyler, TX",
  "Tulsa, OK",
  "Cookeville, TN",
  "McAllen, TX",
  "New York, NY",
  "Boston, MA",
  "Georgetown, DE",
  "Washington, DC",
  "Miami, FL",
  "Chicago, IL",
  "Cleveland, OH",
  "Little Rock, AR",
  "New Orleans, LA",
  "Portland, OR",
  "Louisville, KY",
  "Kansas City, MO",
  "Albuquerque, NM",
  "Honolulu, HI",
  "Kailua-Kona, HI",
  "Buenos Aires, AR",
  "Rio de Janeiro, BR",
  "Sao Paulo, BR",
  "Montevideo, UY",
  "Asuncion, PY",
  "Lima, PE",
  "Santiago, CL",
  "Brasilia, BR",
  "Cordoba, AR",
  "Buenos Aires, AR",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Armenia",
  "Aruba",
  "Ascension Island",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Bangladesh",
  "Belarus",
  "Belgium",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "China",
  "Comoros",
  "Congo",
  "Congo, Democratic Republic of",
  "Croatia",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Diego Garcia",
  "Djibouti",
  "East Timor",
  "Egypt",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Guadeloupe",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "International",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saudi Arabia",
  "Senegal",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "England",
  "Scotland",
  "Wales",
  "Northern Ireland",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Virgin Islands",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export default cityState;
