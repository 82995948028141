import React from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const AccountSettings = ({ handleLogout }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (route) => {
    navigate(route);
    handleMenuClose();
  };

  return (
    <div style={{ marginRight: "2rem" }}>
      <IconButton
        size="large"
        edge="end"
        color="inherit"
        onClick={handleMenuOpen}
      >
        <AccountCircle sx={{ fontSize: 32 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            width: 200,
            borderRadius: 8,
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            marginTop: 12,
          },
        }}
        MenuListProps={{
          sx: {
            padding: 1,
          },
        }}
      >
        <MenuItem
          onClick={() => handleNavigation("/change-password")}
          sx={{
            padding: "12px 16px",
            fontSize: "18px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              color: "primary.main",
            },
          }}
        >
          Change Password
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{
            padding: "12px 16px",
            fontSize: "18px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              color: "error.main",
            },
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AccountSettings;
