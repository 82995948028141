import React, { useState } from "react";
import { Button, Modal, Box, TextField, Grid } from "@mui/material";

const LocatedTable = ({ locatedShows, onEdit }) => {
  const [open, setOpen] = useState(false);
  const [selectedShow, setSelectedShow] = useState(null);

  const handleOpen = (show) => {
    setSelectedShow(show);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedShow(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedShow((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (onEdit && selectedShow) {
      onEdit(selectedShow); // Pass the updated show to the parent for saving
    }
    handleClose();
  };

  const handleDeleteSimilarShow = (index) => {
    setSelectedShow((prev) => ({
      ...prev,
      similarShows: prev.similarShows.filter((_, i) => i !== index),
    }));
  };

  const handleAddSimilarShow = () => {
    setSelectedShow((prev) => ({
      ...prev,
      similarShows: [
        ...prev.similarShows,
        { showName: "", description: "", location: "" },
      ],
    }));
  };

  return (
    <div className="located-shows-table">
      <table>
        <thead>
          <tr>
            <th>Show Name</th>
            <th>City</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Venue</th>
            <th>Description</th>
            <th>Best Hotels</th>
            <th>Platform</th>
            <th>Season</th>
            <th>Similar Shows</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {locatedShows.map((show, index) => (
            <React.Fragment key={index}>
              {/* Original Show Row */}
              <tr>
                <td>{show.showName}</td>
                <td>{show.city || "N/A"}</td>
                <td>{show.startDate || "N/A"}</td>
                <td>{show.endDate || "N/A"}</td>
                <td>{show.venue || "N/A"}</td>
                <td>{show.description || "N/A"}</td>
                <td>
                  {show.bestHotels && show.bestHotels.length > 0 ? (
                    <ol>
                      {show.bestHotels.map((bestHotel, i) => (
                        <li key={i}>
                          {bestHotel.name} - {bestHotel.stars || "N/A"}{" "}
                          {" Stars"}
                        </li>
                      ))}
                    </ol>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>{show.platform || "N/A"}</td>
                <td>{show.season || "N/A"}</td>
                <td>
                  {show.similarShows && show.similarShows.length > 0 ? (
                    <ol>
                      {show.similarShows.map((similarShow, i) => (
                        <li key={i}>
                          {similarShow.showName} -{" "}
                          {similarShow.location || "N/A"}
                        </li>
                      ))}
                    </ol>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpen(show)}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>

      {/* Modal for Editing */}
      {selectedShow && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 900,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <h2>Edit Show</h2>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Show Name"
                    name="showName"
                    value={selectedShow.showName || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="City"
                    name="city"
                    value={selectedShow.city || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Start Date"
                    name="startDate"
                    value={selectedShow.startDate || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="End Date"
                    name="endDate"
                    value={selectedShow.endDate || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Venue"
                    name="venue"
                    value={selectedShow.venue || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Platform"
                    name="platform"
                    value={selectedShow.platform || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Season"
                    name="season"
                    value={selectedShow.season || ""}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Description"
                    name="description"
                    value={selectedShow.description || ""}
                    onChange={handleChange}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
              <h3>Similar Shows</h3>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddSimilarShow}
                sx={{ mt: 2 }}
              >
                Add Similar Show
              </Button>
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto", // Enables vertical scrolling
                  marginBottom: "16px", // Optional margin for spacing
                }}
              >
                <Grid container spacing={2}>
                  {selectedShow.similarShows &&
                    selectedShow.similarShows.map((similarShow, index) => (
                      <Grid
                        container
                        item
                        key={index}
                        spacing={2}
                        style={{ alignItems: "center" }}
                      >
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Show Name"
                            value={similarShow.showName || ""}
                            onChange={(e) => {
                              const updatedSimilarShows = [
                                ...selectedShow.similarShows,
                              ];
                              updatedSimilarShows[index].showName =
                                e.target.value;
                              setSelectedShow((prev) => ({
                                ...prev,
                                similarShows: updatedSimilarShows,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Description"
                            value={similarShow.description || ""}
                            onChange={(e) => {
                              const updatedSimilarShows = [
                                ...selectedShow.similarShows,
                              ];
                              updatedSimilarShows[index].description =
                                e.target.value;
                              setSelectedShow((prev) => ({
                                ...prev,
                                similarShows: updatedSimilarShows,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            margin="normal"
                            label="Location"
                            value={similarShow.location || ""}
                            onChange={(e) => {
                              const updatedSimilarShows = [
                                ...selectedShow.similarShows,
                              ];
                              updatedSimilarShows[index].location =
                                e.target.value;
                              setSelectedShow((prev) => ({
                                ...prev,
                                similarShows: updatedSimilarShows,
                              }));
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDeleteSimilarShow(index)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </div>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default LocatedTable;
