import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import LiveRequestCard from "./LiveRequestCard";
import styles from "./SalesSupportPage.module.css";
import { Button, TablePagination, TextField } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import EditEmailModal from "./EditEmailModal";

const EmailTeamHistory = () => {
  const [emailRequests, setEmailRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const [page, setPage] = useState(0); // Page starts at 0
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedRowId, setSelectedRowId] = useState(null);
  // States for search inputs
  const [companySearch, setCompanySearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [editEmailVisible, setEditEmailVisible] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState("");

  const hotelData = selectedRequest
    ? selectedRequest.pricingData.flatMap((hotel) => hotel.Hotels.map((h) => h))
    : [];
  console.log("hotelData", hotelData);
  console.log("emailRequests", emailRequests);

  console.log(selectedDate);

  // useEffect(() => {
  //   // Stop the loading spinner when emailRequests updates
  //   if (emailRequests.length > 0 || !isLoading) {
  //     setLoading(false);
  //   }
  // }, [emailRequests, isLoading]);

  useEffect(() => {
    if (selectedDate) {
      fetchRequestsByDate(selectedDate);
    }
  }, [selectedDate]);

  // Function to fetch requests based on selected date
  const fetchRequestsByDate = async (date) => {
    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/history-email-requests`,
        { date },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEmailRequests(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching requests by date:", error);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const fetchEmailRequests = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${baseUrl}/api/email-team/history-email-requests`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       // Filter out duplicates based on requestId
  //       const uniqueRequests = [];
  //       const requestIds = new Set();

  //       response.data.forEach((request) => {
  //         if (!requestIds.has(request._id)) {
  //           requestIds.add(request._id);
  //           uniqueRequests.push(request);
  //         }
  //       });

  //       // Sort by a timestamp or other property to ensure most recent requests are at the top
  //       const sortedRequests = uniqueRequests.sort(
  //         (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //       );
  //       setEmailRequests(sortedRequests);

  //       // setEmailRequests(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching live requests:", error);
  //     }
  //   };

  //   fetchEmailRequests();
  // }, []);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value); // Update selectedDate state
  };

  const handleCardClick = (request) => {
    setSelectedRequest(request);
    console.log("request", request);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  const paginatedRequests = emailRequests
    .slice()
    .sort((a, b) => new Date(b.submittedTime) - new Date(a.submittedTime))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const updateEmailFeedback = async (feedback) => {
    if (!selectedRequest) return;

    try {
      const response = await axios.post(
        `${baseUrl}/api/email-team/update-email-feedback`,
        {
          showId: selectedRequest.ShowId,
          leadId: selectedRequest.CompanyId,
          emailFeedback: feedback,
          requestId: selectedRequest._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Feedback sent:", response.data);

      // Update the emailRequests state to reflect the feedback change
      setEmailRequests((prevRequests) =>
        prevRequests.map((req) =>
          req.CompanyId === selectedRequest.CompanyId
            ? {
                ...req,
                companyData: { ...req.companyData, emailFeedback: feedback },
              }
            : req
        )
      );
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const handleRowClick = (id) => {
    setSelectedRowId(id);
  };

  const handleOpenEditEmail = (leadId) => {
    console.log(leadId);
    setSelectedLeadId(leadId);
    setEditEmailVisible(true);
  };

  console.log(selectedLeadId);

  const handleCloseEditEmail = () => {
    setEditEmailVisible(false);
  };

  const handleEmailUpdate = (updatedLead, updatedRequest) => {
    // Update the email in the selectedRequest state
    setSelectedRequest((prevState) => ({
      ...prevState,
      Email: updatedRequest.Email,
      companyData: {
        ...prevState.companyData,
        Email: updatedLead.Email, // Assuming the response contains updated Email
      },
    }));
  };

  // Filtered emailRequests based on search inputs
  const filteredRequests = emailRequests.filter((request) => {
    console.log(emailRequests);

    const lead = request?.companyData?.Lead || "";
    const email = request?.companyData?.Email || "";

    const matchesCompany = lead
      .toLowerCase()
      .includes(companySearch ? companySearch.toLowerCase() : "");

    const matchesEmail = email
      .toLowerCase()
      .includes(emailSearch ? emailSearch.toLowerCase() : "");

    return matchesCompany && matchesEmail;
  });

  const requestsToDisplay =
    filteredRequests.length > 0 ? filteredRequests : paginatedRequests;

  // Paginate filtered requests if any
  const paginatedFilteredRequests = requestsToDisplay.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="loading-message">Loading...</div>
        <div className="spinner"></div>
      </div>
    );
  }

  const renderTableRow = (hotel) => {
    return (
      <TableRow
        key={hotel._id}
        onClick={() => handleRowClick(hotel._id)}
        sx={{
          background: selectedRowId === hotel._id && "#A9A9A9",
          cursor: "pointer",
          color: selectedRowId === hotel._id ? "#fff" : "inherit",
          "&:hover": {
            background: "#A9A9A9",
            color: "#fff",
          },
        }}
      >
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Hotel Name"]}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel.Address}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel.Stars}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel.SellingPrice
            ? hotel.SellingPrice
            : hotel["Average Nightly Rate with Markup"]}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Boarding Type"]}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", wordBreak: "keep-all" }}>
          {hotel.Distance}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Room Type"]}
        </TableCell>
        <TableCell
          sx={{
            borderRight: "2px solid #e0e0e0",
            fontSize: "14px",
            fontWeight: 550,
            whiteSpace: "nowrap",
            wordBreak: "keep-all",
            padding: "4px 10px",
          }}
        >
          {hotel["Free Cancellation Until"]}
        </TableCell>
        {/* <TableCell
            sx={{
              borderRight: "2px solid #e0e0e0",
              fontSize: "14px",
              fontWeight: 550,
              whiteSpace: "nowrap",
              wordBreak: "keep-all",
              padding: "4px 10px",
            }}
          >
            {hotel["Online Price"]}
          </TableCell> */}
      </TableRow>
    );
  };

  return (
    <div className={styles.container}>
      <div className="date-picker-container">
        <TextField
          type="date"
          label="Select Date"
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginTop: "15px", width: "200px", padding: "8px" }}
        />
      </div>
      <div className={styles.content}>
        <ul className={styles.requestList}>
          <div
            className="search-email-req-container"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              marginBottom: "2rem",
            }}
          >
            <div className="assign-lead-filter-contain">
              <input
                type="text"
                id="company-search"
                placeholder="Search by Company Name"
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  marginLeft: "10px",
                  width: "280px",
                }}
                value={companySearch} // Bind state
                onChange={(e) => setCompanySearch(e.target.value)} // Update state
              />
            </div>
            <div className="assign-lead-filter-contain">
              <input
                type="text"
                id="email-search"
                placeholder="Search by Email"
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  marginLeft: "10px",
                  width: "280px",
                }}
                value={emailSearch} // Bind state
                onChange={(e) => setEmailSearch(e.target.value)} // Update state
              />
            </div>
          </div>
          <h2>Email Requests</h2>
          <TablePagination
            component="div"
            count={emailRequests.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20]}
          />
          {requestsToDisplay.length === 0 ? (
            <p>No requests match your search criteria.</p>
          ) : (
            paginatedFilteredRequests.map((request) => (
              <li
                key={request._id}
                onClick={() => handleCardClick(request)}
                style={{
                  background:
                    request.State === "Sent"
                      ? "green"
                      : request.State === "Error Sent"
                      ? "red"
                      : "transparent",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                <LiveRequestCard message={{ data: request }} />
              </li>
            ))
          )}
        </ul>
        <div className={styles.requestDetails}>
          <h2>Request Details : </h2>
          {selectedRequest && (
            <div>
              <p>
                <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
              </p>
              <p>
                <strong>Company:</strong> {selectedRequest?.companyData?.Lead}
              </p>
              <p>
                <strong>Email:</strong>{" "}
                {selectedRequest?.Email || selectedRequest?.companyData?.Email}
              </p>
              <p>
                <strong>Contact Name:</strong>
                {selectedRequest?.ContactName}
              </p>

              <p>
                <strong>Request:</strong> {selectedRequest?.Request}
              </p>
              <p>
                <strong>Agent Name:</strong> {selectedRequest?.agentName}
              </p>
              <p>
                <strong>Requested Data:</strong>
                {selectedRequest?.RequestedData}
              </p>
              <p>
                <strong>Email notes:</strong>
                {selectedRequest?.notes}
              </p>
              <p>
                <strong>State:</strong> {selectedRequest?.State}
              </p>

              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginRight: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Sent")}
              >
                Send Correct Email
              </Button>

              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginLeft: "0.5rem",
                  backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
                  border: "none",
                }}
                onClick={() => updateEmailFeedback("Error Sent")}
              >
                Send Error Email
              </Button>
              <Button
                variant="outlined"
                style={{
                  marginTop: "1rem",
                  marginLeft: "0.5rem",

                  border: "none",
                }}
                onClick={() => handleOpenEditEmail(selectedRequest.CompanyId)}
              >
                Edit Email
              </Button>
            </div>
          )}
          {selectedRequest && (
            <div className={styles.emailPriceTable__container}>
              <TableContainer
                component={Paper}
                sx={{
                  overflow: "auto",
                  maxHeight: "600px",
                  // minWidth: "800px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  maxWidth: "100%",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "Hotel Name",
                        "Address",
                        "Stars",
                        "Selling Price",
                        "Boarding Type",
                        "Distance",
                        "Room Type",
                        "Free Cancellation Until",
                        "Online Price",
                      ].map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            background: "#8b5a5a",
                            color: "white",
                            // whiteSpace: "nowrap",
                            // wordBreak: "keep-all",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(
                      selectedRequest?.emailPricingFeedback?.length > 0
                        ? selectedRequest?.emailPricingFeedback
                        : selectedRequest?.pricingData?.[0]?.Hotels
                    )
                      ? (
                          (selectedRequest?.emailPricingFeedback?.length > 0
                            ? selectedRequest.emailPricingFeedback
                            : selectedRequest?.pricingData?.[0]?.Hotels) || []
                        ).map((hotel) => renderTableRow(hotel))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
      {editEmailVisible && (
        <EditEmailModal
          onClose={handleCloseEditEmail} // Close function
          token={token} // Pass the token
          leadId={selectedLeadId}
          requestId={selectedRequest._id}
          onUpdate={handleEmailUpdate}
        />
      )}
    </div>
  );
};
export default EmailTeamHistory;

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import LiveRequestCard from "./LiveRequestCard";
// import styles from "./SalesSupportPage.module.css";
// import { Button } from "@mui/material";
// import { AuthContext } from "../context/AuthContext";
// import { baseUrl } from "../../api/Api";
// import {
//   Table,
//   TableHead,
//   TableBody,
//   TableCell,
//   TableRow,
//   TableContainer,
//   Paper,
// } from "@mui/material";

// const EmailTeamHistory = () => {
//   const [emailRequests, setEmailRequests] = useState([]);
//   const [selectedRequest, setSelectedRequest] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const { token } = useContext(AuthContext);
//   const [selectedRowId, setSelectedRowId] = useState(null);
//     // States for search inputs
//     const [companySearch, setCompanySearch] = useState("");
//     const [emailSearch, setEmailSearch] = useState("");

//   const hotelData = emailRequests.flatMap((item) =>
//     item.pricingData.flatMap((hotel) => hotel.Hotels.map((h) => h))
//   );
//   console.log("hotelData", hotelData);
//   console.log("emailRequests", emailRequests);

//   useEffect(() => {
//     const fetchEmailRequests = async () => {
//       try {
//         const response = await axios.get(
//           `${baseUrl}/api/email-team/history-email-requests`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setEmailRequests(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching live requests:", error);
//       }
//     };

//     fetchEmailRequests();
//   }, []);

//   const handleCardClick = (request) => {
//     setSelectedRequest(request);
//     console.log("request", request);
//   };

//   const updateEmailFeedback = async (feedback) => {
//     if (!selectedRequest) return;

//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/email-team/update-email-feedback`,
//         {
//           showId: selectedRequest.ShowId,
//           leadId: selectedRequest.CompanyId,
//           emailFeedback: feedback,
//           requestId: selectedRequest._id,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("Feedback sent:", response.data);

//       // Update the emailRequests state to reflect the feedback change
//       setEmailRequests((prevRequests) =>
//         prevRequests.map((req) =>
//           req.CompanyId === selectedRequest.CompanyId
//             ? {
//                 ...req,
//                 companyData: { ...req.companyData, emailFeedback: feedback },
//               }
//             : req
//         )
//       );
//     } catch (error) {
//       console.error("Error sending feedback:", error);
//     }
//   };

//   const handleRowClick = (id) => {
//     setSelectedRowId(id);
//   };

//   // Filtered emailRequests based on search inputs
//   const filteredRequests = emailRequests.filter((request) => {
//     const matchesCompany =
//       request.companyData.Lead.toLowerCase().includes(companySearch.toLowerCase());
//     const matchesEmail =
//       request.companyData.Email.toLowerCase().includes(emailSearch.toLowerCase());
//     return matchesCompany && matchesEmail;
//   });

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         Loading...
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       <h1>Email Team Page</h1>
//       <div className={styles.content}>
//         <ul className={styles.requestList}>
//           <div className="search-email-req-container" style={{ display: "flex", flexDirection: "column", gap: 5 }}>
//             <div className="assign-lead-filter-contain">
//             <input
//                 type="text"
//                 id="company-search"
//                 placeholder="Search by Company Name"
//                 style={{
//                   padding: "8px",
//                   borderRadius: "4px",
//                   border: "1px solid #ccc",
//                   marginLeft: "10px",
//                   width: "280px",
//                 }}
//                 value={companySearch} // Bind state
//                 onChange={(e) => setCompanySearch(e.target.value)} // Update state
//               />
//             </div>
//             <div className="assign-lead-filter-contain">
//             <input
//                 type="text"
//                 id="email-search"
//                 placeholder="Search by Email"
//                 style={{
//                   padding: "8px",
//                   borderRadius: "4px",
//                   border: "1px solid #ccc",
//                   marginLeft: "10px",
//                   width: "280px",
//                 }}
//                 value={emailSearch} // Bind state
//                 onChange={(e) => setEmailSearch(e.target.value)} // Update state
//               />
//             </div>
//           </div>
//           <h2>Email Requests</h2>
//           {filteredRequests.length === 0 ? (
//           <p>No requests match your search criteria.</p>
//         ) : (
//           filteredRequests.slice().reverse().map((request) => (
//             <li
//               key={request._id}
//               onClick={() => handleCardClick(request)}
//               style={{
//                 background:
//                   request.State === "Sent"
//                     ? "green"
//                     : request.State === "Error Sent"
//                     ? "red"
//                     : "transparent",
//                 color: "white",
//                 borderRadius: "5px",
//               }}
//             >
//               <LiveRequestCard message={{ data: request }} />
//             </li>
//           ))
//         )}
//         </ul>
//         <div className={styles.requestDetails}>
//           <h2>Request Details : </h2>
//           {selectedRequest && (
//             <div>
//             <p>
//               <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
//             </p>
//             <p>
//               <strong>Company:</strong> {selectedRequest?.companyData?.Lead}
//             </p>
//             <p>
//               <strong>Email:</strong> {selectedRequest?.Email || selectedRequest?.companyData?.Email }
//             </p>
//             <p>
//               <strong>Contact Name:</strong>
//               {selectedRequest?.ContactName}
//             </p>

//             <p>
//               <strong>Request:</strong> {selectedRequest?.Request}
//             </p>
//             <p>
//               <strong>Agent Name:</strong> {selectedRequest?.agentName}
//             </p>
//             <p>
//               <strong>Requested Data:</strong>
//               {selectedRequest?.RequestedData}
//             </p>
//             <p>
//               <strong>Email notes:</strong>
//               {selectedRequest?.notes}
//             </p>
//             <p>
//               <strong>State:</strong> {selectedRequest?.State}
//             </p>
//             <p>
//               <strong>Send Time :</strong>
//             </p>
//               <Button
//                 variant="outlined"
//                 style={{
//                   marginTop: "1rem",
//                   marginRight: "0.5rem",
//                   backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
//                   border: "none",
//                 }}
//                 onClick={() => updateEmailFeedback("Sent")}
//               >
//                 Send Correct Email
//               </Button>

//               <Button
//                 variant="outlined"
//                 style={{
//                   marginTop: "1rem",
//                   marginLeft: "0.5rem",
//                   backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
//                   border: "none",
//                 }}
//                 onClick={() => updateEmailFeedback("Error Sent")}
//               >
//                 Send Error Email
//               </Button>
//             </div>
//           )}
//           {selectedRequest && (
//             <div className={styles.emailPriceTable__container}>
//               <TableContainer
//                 component={Paper}
//                 sx={{
//                   overflow: "auto",
//                   maxHeight: "600px",
//                   // minWidth: "800px",
//                   borderRadius: "8px",
//                   boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
//                   maxWidth: "100%",
//                 }}
//               >
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       {[
//                         "Hotel Name",
//                         "Address",
//                         "Stars",
//                         "Selling Price",
//                         "Boarding Type",
//                         "Distance",
//                         "Room Type",
//                         "Free Cancellation Until",
//                         "Online Price",
//                       ].map((header, index) => (
//                         <TableCell
//                           key={index}
//                           sx={{
//                             background: "#8b5a5a",
//                             color: "white",
//                             // whiteSpace: "nowrap",
//                             // wordBreak: "keep-all",
//                           }}
//                         >
//                           {header}
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {hotelData.map((hotel, index) => (
//                       <TableRow
//                         key={hotel._id}
//                         onClick={() => handleRowClick(hotel._id)}
//                         sx={{
//                           background: selectedRowId === hotel._id && "#A9A9A9",
//                           cursor: "pointer",
//                           color:
//                             selectedRowId === hotel._id ? "#fff" : "inherit",
//                           "&:hover": {
//                             background: "#A9A9A9",
//                             color: '"#fff',
//                           },
//                         }}
//                       >
//                         <TableCell
//                           sx={
//                             {
//                               // whiteSpace: "nowrap",
//                               // wordBreak: "keep-all",
//                             }
//                           }
//                         >
//                           {hotel["Hotel Name"]}
//                         </TableCell>
//                         <TableCell>{hotel.Address}</TableCell>
//                         <TableCell>{hotel.Stars}</TableCell>
//                         <TableCell>{hotel.SellingPrice}</TableCell>
//                         <TableCell>{hotel["Boarding Type"]}</TableCell>
//                         <TableCell
//                           sx={{
//                             whiteSpace: "nowrap",
//                             wordBreak: "keep-all",
//                           }}
//                         >
//                           {hotel.Distance}
//                         </TableCell>
//                         <TableCell
//                           sx={{
//                             whiteSpace: "nowrap",
//                             wordBreak: "keep-all",
//                           }}
//                         >
//                           {hotel["Room Type"]}
//                         </TableCell>
//                         <TableCell>
//                           {hotel["Free Cancellation Until"]}
//                         </TableCell>
//                         <TableCell>{hotel["Online Price"]}</TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default EmailTeamHistory;

// import React, { useState, useEffect, useContext } from "react";
// import axios from "axios";
// import LiveRequestCard from "./LiveRequestCard";
// import styles from "./SalesSupportPage.module.css";
// import { Button } from "@mui/material";
// import { AuthContext } from "../context/AuthContext";
// import { baseUrl } from "../../api/Api";
// import {
//   Table,
//   TableHead,
//   TableBody,
//   TableCell,
//   TableRow,
//   TableContainer,
//   Paper,
// } from "@mui/material";

// const EmailTeamHistory = () => {
//   const [emailRequests, setEmailRequests] = useState([]);
//   const [selectedRequest, setSelectedRequest] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const { token } = useContext(AuthContext);
//   const [selectedRowId, setSelectedRowId] = useState(null);

//   console.log("emailRequests", emailRequests);
//   const hotelData = emailRequests.flatMap((item) =>
//     item.pricingData.flatMap((hotel) => hotel.Hotels.map((h) => h))
//   );
//   console.log("hotelData", hotelData);
//   console.log("emailRequests", emailRequests);

//   useEffect(() => {
//     const fetchEmailRequests = async () => {
//       try {
//         const response = await axios.get(
//           `${baseUrl}/api/email-team/history-email-requests`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setEmailRequests(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching live requests:", error);
//       }
//     };

//     fetchEmailRequests();
//   }, []);

//   const handleCardClick = (request) => {
//     setSelectedRequest(request);
//     console.log("request", request);
//   };

//   const updateEmailFeedback = async (feedback) => {
//     if (!selectedRequest) return;

//     try {
//       const response = await axios.post(
//         `${baseUrl}/api/email-team/update-email-feedback`,
//         {
//           showId: selectedRequest.ShowId,
//           leadId: selectedRequest.CompanyId,
//           emailFeedback: feedback,
//           requestId: selectedRequest._id,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       console.log("Feedback sent:", response.data);

//       // Update the emailRequests state to reflect the feedback change
//       setEmailRequests((prevRequests) =>
//         prevRequests.map((req) =>
//           req.CompanyId === selectedRequest.CompanyId
//             ? {
//                 ...req,
//                 companyData: { ...req.companyData, emailFeedback: feedback },
//               }
//             : req
//         )
//       );
//     } catch (error) {
//       console.error("Error sending feedback:", error);
//     }
//   };

//   const handleRowClick = (id) => {
//     setSelectedRowId(id);
//   };

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         Loading...
//         <div className="spinner"></div>
//       </div>
//     );
//   }

//   return (
//     <div className={styles.container}>
//       <h1>Email Team Page</h1>
//       <div className={styles.content}>
//         <ul className={styles.requestList}>
//           <div className="search-email-req-container" style={{ display: "flex", flexDirection: "column", gap: 5 }}>
//             <div className="assign-lead-filter-contain">
//               <input
//                 type="text"
//                 id="company-search"
//                 placeholder="Search by Company Name"
//                 style={{
//                   padding: "8px",
//                   borderRadius: "4px",
//                   border: "1px solid #ccc",
//                   marginLeft: "10px",
//                   width: "280px",
//                 }}
//               />
//             </div>
//             <div className="assign-lead-filter-contain">
//               <input
//                 type="text"
//                 id="company-search"
//                 placeholder="Search by Email"
//                 style={{
//                   padding: "8px",
//                   borderRadius: "4px",
//                   border: "1px solid #ccc",
//                   marginLeft: "10px",
//                   width: "280px",
//                 }}
//               />
//             </div>
//           </div>
//           <h2>Email Requests</h2>
//           {emailRequests
//             .slice()
//             .reverse()
//             .map((request, index) => (
//               <li
//                 key={request._id}
//                 onClick={() => handleCardClick(request)}
//                 style={{
//                   background:
//                     request.companyData.emailFeedback === "Sent"
//                       ? "green"
//                       : request.companyData.emailFeedback === "Error Sent"
//                       ? "red"
//                       : "transparent",
//                   color: "white",
//                   borderRadius: "5px",
//                 }}
//               >
//                 <LiveRequestCard message={{ data: request }} />
//               </li>
//             ))}
//         </ul>
//         <div className={styles.requestDetails}>
//           <h2>Request Details : </h2>
//           {selectedRequest && (
//             <div>
//               <p>
//                 <strong>Show Name:</strong> {selectedRequest?.showData?.Show}
//               </p>
//               <p>
//                 <strong>Company:</strong> {selectedRequest?.companyData?.Lead}
//               </p>
//               <p>
//                 <strong>Email:</strong> {selectedRequest?.companyData?.Email}
//               </p>

//               <p>
//                 <strong>Request:</strong> {selectedRequest?.Request}
//               </p>
//               <p>
//                 <strong>Agent Name:</strong> {selectedRequest?.agentName}
//               </p>
//               <p>
//                 <strong>Requested Data:</strong>
//                 {selectedRequest?.RequestedData}
//               </p>
//               <p>
//                 <strong>State:</strong> {selectedRequest?.State}
//               </p>
//               <Button
//                 variant="outlined"
//                 style={{
//                   marginTop: "1rem",
//                   marginRight: "0.5rem",
//                   backgroundImage: "linear-gradient(90deg, #32CD32, #228B22)",
//                   border: "none",
//                 }}
//                 onClick={() => updateEmailFeedback("Sent")}
//               >
//                 Send Correct Email
//               </Button>

//               <Button
//                 variant="outlined"
//                 style={{
//                   marginTop: "1rem",
//                   marginLeft: "0.5rem",
//                   backgroundImage: "linear-gradient(90deg, #FF6347, #B22222)",
//                   border: "none",
//                 }}
//                 onClick={() => updateEmailFeedback("Error Sent")}
//               >
//                 Send Error Email
//               </Button>
//             </div>
//           )}
//           {selectedRequest && (
//             <div className={styles.emailPriceTable__container}>
//               <TableContainer
//                 component={Paper}
//                 sx={{
//                   overflow: "auto",
//                   maxHeight: "600px",
//                   // minWidth: "800px",
//                   borderRadius: "8px",
//                   boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
//                   maxWidth: "100%",
//                 }}
//               >
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                       {[
//                         "Hotel Name",
//                         "Address",
//                         "Stars",
//                         "Selling Price",
//                         "Boarding Type",
//                         "Distance",
//                         "Room Type",
//                         "Free Cancellation Until",
//                         "Online Price",
//                       ].map((header, index) => (
//                         <TableCell
//                           key={index}
//                           sx={{
//                             background: "#8b5a5a",
//                             color: "white",
//                             // whiteSpace: "nowrap",
//                             // wordBreak: "keep-all",
//                           }}
//                         >
//                           {header}
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {hotelData.map((hotel, index) => (
//                       <TableRow
//                         key={hotel._id}
//                         onClick={() => handleRowClick(hotel._id)}
//                         sx={{
//                           background: selectedRowId === hotel._id && "#A9A9A9",
//                           cursor: "pointer",
//                           color:
//                             selectedRowId === hotel._id ? "#fff" : "inherit",
//                           "&:hover": {
//                             background: "#A9A9A9",
//                             color: '"#fff',
//                           },
//                         }}
//                       >
//                         <TableCell
//                           sx={
//                             {
//                               // whiteSpace: "nowrap",
//                               // wordBreak: "keep-all",
//                             }
//                           }
//                         >
//                           {hotel["Hotel Name"]}
//                         </TableCell>
//                         <TableCell>{hotel.Address}</TableCell>
//                         <TableCell>{hotel.Stars}</TableCell>
//                         <TableCell>{hotel.SellingPrice}</TableCell>
//                         <TableCell>{hotel["Boarding Type"]}</TableCell>
//                         <TableCell
//                           sx={{
//                             whiteSpace: "nowrap",
//                             wordBreak: "keep-all",
//                           }}
//                         >
//                           {hotel.Distance}
//                         </TableCell>
//                         <TableCell
//                           sx={{
//                             whiteSpace: "nowrap",
//                             wordBreak: "keep-all",
//                           }}
//                         >
//                           {hotel["Room Type"]}
//                         </TableCell>
//                         <TableCell>
//                           {hotel["Free Cancellation Until"]}
//                         </TableCell>
//                         <TableCell>{hotel["Online Price"]}</TableCell>
//                       </TableRow>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default EmailTeamHistory;
