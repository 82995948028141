import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Select,
  Grid,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import styles from "./AssignLeader.module.css";
import { Circle } from "@mui/icons-material";
import { format } from "date-fns";
import { baseUrl } from "../../api/Api";
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import SetUserPasswordModal from "./SetUserPasswordModal";

const AssignLeader = () => {
  const [allUsersData, setAllUsersData] = useState([]);
  const [filteredUsersData, setFilteredUsersData] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [error, setError] = useState(null);
  const { token } = useContext(AuthContext);
  const [openPopup, setOpenPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);

  const roles = [
    "agent",
    "agent closer",
    "team leader",
    "sales manager",
    "sales support",
    "locator",
    "locator manager",
    "leads senior",
    "leads junior",
    "email team",
    "leads manager",
    "pricing manager",
    "pricing senior",
    "pricing junior",
    "locator manager",
    "operations manager",
    "lead generator",
  ];

  const fetchUsersData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/super/get-all-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllUsersData(response.data.users);
      setFilteredUsersData(response.data.users);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching users data", error);
      setLoading(false);
    }
  };

  const getLoggedinUsers = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/users/logged-in-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOnlineUsers(response.data);
    } catch (error) {
      console.log("get loggedin users error", error);
    }
  };
  useEffect(() => {
    fetchUsersData();
    getLoggedinUsers();
  }, []);

  const handleTimezoneChange = (event) => {
    const value = event.target.value;
    setSelectedTimezone(value);
    filterUsers(value, selectedRole);
  };

  const handleRoleChange = (event) => {
    const value = event.target.value;
    setSelectedRole(value);
    filterUsers(selectedTimezone, value);
  };

  const handleStateChange = (event) => {
    const value = event.target.value;
    setSelectedState(value);
    filterUsers(selectedTimezone, selectedRole, value);
  };

  const filterUsers = (timezone, role, state) => {
    let filteredData = allUsersData;

    if (timezone) {
      filteredData = filteredData.filter(
        (user) => user.timezone?.toLowerCase() === timezone.toLowerCase()
      );
    }

    if (role) {
      filteredData = filteredData.filter(
        (user) => user.role?.toLowerCase() === role.toLowerCase()
      );
    }

    if (state) {
      console.log(state);
      const isLoggedIn = state === "true";
      filteredData = filteredData.filter(
        (user) => user.loggedIn === isLoggedIn
      );
    }

    setFilteredUsersData(filteredData);
  };

  const getDropdownOptions = (role) => {
    if (role === "agent") {
      return allUsersData.filter((u) => u.role === "team leader");
    } else if (role === "team leader") {
      return allUsersData.filter((u) => u.role === "sales manager");
    } else {
      return [];
    }
  };

  const handleSetPasswordOpenModal = (userId, email) => {
    setSelectedUser(userId);
    setSelectedEmail(email);
    setPasswordModalOpen(true);
  };

  const handleCloseModal = () => {
    setPasswordModalOpen(false);
  };

  const handleAccessClick = (userId) => {
    setSelectedUser(userId);
    setOpenPopup(true); // Open popup when the icon is clicked
  };

  const handleGrantAccess = async () => {
    console.log(selectedUser);

    try {
      await axios.put(
        `${baseUrl}/api/super/grant-access/${selectedUser}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
          },
        }
      );
      setFilteredUsersData((prevData) =>
        prevData.map((user) =>
          user._id === selectedUser ? { ...user, accessAllowed: true } : user
        )
      );
      // After successful request, close the popup and refresh or update state
      setOpenPopup(false);
      alert("Access granted");
      // Optionally, refresh or update the state to reflect changes in the table
    } catch (error) {
      console.error("Error granting access", error);
    }
  };

  const handleRevokeAccess = async () => {
    try {
      await axios.put(
        `${baseUrl}/api/super/revoke-access/${selectedUser}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in the Authorization header
          },
        }
      );
      // After successful request, close the popup and refresh or update state
      setFilteredUsersData((prevData) =>
        prevData.map((user) =>
          user._id === selectedUser ? { ...user, accessAllowed: false } : user
        )
      );
      setOpenPopup(false);
      alert("Access revoked");
      // Optionally, refresh or update the state to reflect changes in the table
    } catch (error) {
      console.error("Error revoking access", error);
    }
  };

  const handleLeaderSelection = async (userId, leaderId) => {
    try {
      await axios.put(
        `${baseUrl}/api/super/assign-team-leader/${userId}`,
        { teamLeaderId: leaderId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAllUsersData((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId
            ? {
                ...user,
                teamLeader: filteredUsersData.find((u) => u._id === leaderId),
              }
            : user
        )
      );
      setFilteredUsersData((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId
            ? {
                ...user,
                teamLeader: filteredUsersData.find((u) => u._id === leaderId),
              }
            : user
        )
      );
      setError(null);
      setEditingUserId(null); // Exit editing mode after saving
    } catch (error) {
      console.error("Error updating leader:", error);
      setError("Failed to update leader. Please try again.");
    }
  };

  const handleCellClick = (userId) => {
    setEditingUserId(userId);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    return format(date, "MMM dd, yyyy h:mm a");
  };

  const handleUpdateUser = async () => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/super/update-user-data/${selectedUser._id}`,
        {
          name: selectedUser.name,
          email: selectedUser.email,
          role: selectedUser.role,
          timezone: selectedUser.timezone,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update local state or refetch data if needed

        console.log("User updated successfully", response.data);
        setSelectedTimezone("");
        setSelectedRole("");
        setSelectedState("");
        fetchUsersData();
      } else {
        console.error("Failed to update user");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setOpenEditPopup(false);
    }
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <h2>Online Users Count</h2>
          <Grid container spacing={2}>
            {onlineUsers.map((user, index) => (
              <Grid item xs={6} sm={3} md={2} key={index}>
                <Box
                  sx={{
                    padding: 1,
                    textAlign: "center",
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="subtitle1">{user.role}</Typography>
                  <Typography variant="h6">
                    {user.loggedInUsers}{" "}
                    {/* Display any other relevant information */}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
          <div className={styles.filters}>
            <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
              <InputLabel id="timezone-label">Timezone</InputLabel>
              <Select
                labelId="timezone-label"
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                label="Timezone"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="us">US</MenuItem>
                <MenuItem value="uk">UK</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                value={selectedState}
                onChange={handleStateChange}
                label="State"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="true">Online</MenuItem>
                <MenuItem value="false">Offline</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ minWidth: 180 }}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                value={selectedRole}
                onChange={handleRoleChange}
                label="Role"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role} value={role.toLowerCase()}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {error && <Typography color="error">{error}</Typography>}

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Role</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Logged In</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Leader</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Timezone</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Last Seen</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Team</strong>
                  </TableCell>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Access</strong>
                  </TableCell>
                  <TableRow>
                    <TableCell sx={{ color: "wheat" }}>
                      <strong>Edit</strong>
                    </TableCell>
                  </TableRow>
                  <TableCell sx={{ color: "wheat" }}>
                    <strong>Set Password</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsersData.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell
                      sx={{
                        width: "120px",
                        maxWidth: "120px",
                        textAlign: "center",
                      }}
                    >
                      <Circle
                        sx={{
                          color: user.loggedIn ? "green" : "red",
                          fontSize: "1rem",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ width: "250px" }}
                      onClick={() => handleCellClick(user._id)}
                    >
                      {editingUserId === user._id ? (
                        <FormControl fullWidth>
                          <InputLabel id={`leader-select-label-${user._id}`}>
                            {user.teamLeader
                              ? user.teamLeader.name
                              : "Select Leader"}
                          </InputLabel>
                          <Select
                            labelId={`leader-select-label-${user._id}`}
                            value={user.teamLeader ? user.teamLeader._id : ""}
                            onChange={(e) =>
                              handleLeaderSelection(user._id, e.target.value)
                            }
                            displayEmpty
                          >
                            {getDropdownOptions(user.role).map((leader) => (
                              <MenuItem key={leader._id} value={leader._id}>
                                {leader.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <span>
                          {user.teamLeader
                            ? user.teamLeader.name
                            : "No leader assigned"}
                        </span>
                      )}
                    </TableCell>
                    <TableCell>{user.timezone}</TableCell>
                    <TableCell>{formatDate(user.lastSeen)}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setSelectedUser(user);
                          setOpenModal(true);
                        }}
                      >
                        View Team
                      </Button>
                    </TableCell>
                    <TableCell sx={{ color: "wheat", textAlign: "center" }}>
                      {user.accessAllowed ? (
                        <CheckCircleIcon
                          sx={{ color: "green" }}
                          onClick={() => handleAccessClick(user._id)}
                        />
                      ) : (
                        <CancelIcon
                          sx={{ color: "red" }}
                          onClick={() => handleAccessClick(user._id)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <EditIcon
                        sx={{ cursor: "pointer", color: "blue" }}
                        onClick={() => {
                          setSelectedUser(user);
                          setOpenEditPopup(true);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleSetPasswordOpenModal(user._id, user.email)
                        }
                      >
                        Set Password
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {passwordModalOpen && (
            <SetUserPasswordModal
              open={passwordModalOpen}
              handleClose={handleCloseModal}
              userId={selectedUser}
              userEmail={selectedEmail}
            />
          )}
          <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
            <DialogTitle>Change Access</DialogTitle>
            <DialogContent>
              <p>Do you want to allow or revoke access for this user?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleGrantAccess} color="primary">
                Allow Access
              </Button>
              <Button onClick={handleRevokeAccess} color="secondary">
                Revoke Access
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openModal}
            onClose={handleModalClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Team Members</DialogTitle>
            <DialogContent>
              {selectedUser ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: "wheat" }}>Name</TableCell>
                        <TableCell sx={{ color: "wheat" }}>Email</TableCell>
                        <TableCell sx={{ color: "wheat" }}>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedUser.team && selectedUser.team.length > 0 ? (
                        selectedUser.team.map((member) => (
                          <TableRow key={member._id}>
                            <TableCell>{member.name}</TableCell>
                            <TableCell>{member.email}</TableCell>
                            <TableCell>{member.role}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Typography>No team members</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No user selected</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          {/* Modal for Editing User */}
          <Dialog
            open={openEditPopup}
            onClose={() => setOpenEditPopup(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>
              {selectedUser && (
                <Box>
                  <TextField
                    fullWidth
                    label="Name"
                    value={selectedUser.name || ""}
                    onChange={(e) =>
                      setSelectedUser((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={selectedUser.email || ""}
                    onChange={(e) =>
                      setSelectedUser((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    sx={{ mt: 2 }}
                  />
                  {selectedUser.role !== "agent" && (
                    <>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="role-select-label">Role</InputLabel>
                        <Select
                          labelId="role-select-label"
                          value={selectedUser.role || ""}
                          onChange={(e) =>
                            setSelectedUser((prev) => ({
                              ...prev,
                              role: e.target.value,
                            }))
                          }
                        >
                          {roles.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="timezone-select-label">
                          Timezone
                        </InputLabel>
                        <Select
                          labelId="timezone-select-label"
                          value={selectedUser.timezone || ""}
                          onChange={(e) =>
                            setSelectedUser((prev) => ({
                              ...prev,
                              timezone: e.target.value,
                            }))
                          }
                        >
                          <MenuItem value="us">US</MenuItem>
                          <MenuItem value="uk">UK</MenuItem>
                          <MenuItem value="dynamic">Dynamic</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenEditPopup(false)}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleUpdateUser}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default AssignLeader;
