import React, { useContext, useEffect, useState } from "react";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import "./SalesManager.css";
import { AuthContext } from "../../components/context/AuthContext";
import SalesManagerTeamTable from "../../components/salesManager/SalesManagerTeamTable";
import AllAgents from "../../components/salesManager/AllAgents";
import { baseUrl } from "../../api/Api";
import axios from "axios";
import AssignedLeadsTable from "../../components/teamLeader/AssignedLeadsTable";
import TeamLeaderPopup from "../../components/popUp/TeamLeaderPopup";
import ExistingLeads from "../../components/ExistingLeads/ExistingLeads";
import CallbackLeadsTable from "../../components/teamLeader/CallbackLeadsTable";

const SalesManager = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isViewTeamActive, setIsViewTeamActive] = useState(true);
  const [isAssignLeadesActive, setIsAssignLeadesActive] = useState(false);
  const [isCallbackLeadesActive, setIsCallbackLeadesActive] = useState(false);
  const [isAccessLeadsActive, setIsAccessLeadsActive] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [overLayStatusActive, setOverLayStatusActive] = useState(false);
  const [assignedLeads, setAssignedLeads] = useState([]);
  const [callbackLeads, setCallbackLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedCallbackDate, setSelectedCallbackDate] = useState("");

  const { role, token } = useContext(AuthContext);

  const [dataOfRowId, setDataOfRowId] = useState({
    showId: null,
    companyId: null,
    Show: null,
    Company: null,
    Timezone: null,
    Status: null,
    "Closer Note": null,
    closerStatus: null,
    "Rejection Reason": null,
    Notes: null,
    "Contact Name": null,
    "DM Rating": null,
    Email: null,
    "Days to Call": null,
    "Last Contact": null,
    "Closing Date": null,
    Hotel: null,
    Budget: null,
    Rooms: null,
    "Check-in": null,
    "Check-out": null,
    "/ Room Stay": null,
    "Total Stay": null,
    "Lead Date": null,
    "Email Type": null,
  });
  console.log("dataOfRowId sales support", dataOfRowId);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const showTeam = () => {
    setIsViewTeamActive(true);
    setIsAssignLeadesActive(false);
    setIsCallbackLeadesActive(false);
    setIsAccessLeadsActive(false);
  };

  const showAssignedLeads = () => {
    setIsAssignLeadesActive(true);
    setIsCallbackLeadesActive(false);
    setIsViewTeamActive(false);
    setIsAccessLeadsActive(false);
  };

  const showCallbackLeads = () => {
    setIsAssignLeadesActive(false);
    setIsCallbackLeadesActive(true);
    setIsViewTeamActive(false);
    setIsAccessLeadsActive(false);
  };
  const showAccessLeads = () => {
    setIsAccessLeadsActive(true);
    setIsAssignLeadesActive(false);
    setIsCallbackLeadesActive(false);
    setIsViewTeamActive(false);
  };

  const fetchAssignedLeads = async (date) => {
    setIsLoading(true);
    const url = `${baseUrl}/api/sales-manager/get-leads-to-close`;

    try {
      const response = await axios.post(
        url,
        { date },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAssignedLeads(response.data);
      console.log("response.data", response.data);
    } catch (error) {
      console.log("Fetch Assigned Leads Error ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      fetchAssignedLeads(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedCallbackDate) {
      fetchCallbackLeads(selectedCallbackDate);
    }
  }, [selectedCallbackDate]);


  const fetchCallbackLeads = async (date) => {
    setIsLoading(true);
    const url = `${baseUrl}/api/sales-manager/get-callback-leads`;

    try {
      const response = await axios.post(
        url,
        { date },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCallbackLeads(response.data);
      console.log("response.data", response.data);
    } catch (error) {
      console.log("Fetch Assigned Leads Error ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // fetchAssignedLeads();
    // fetchCallbackLeads();
  }, []);

  const handleCloseUpdateSubmit = async () => {
    if (!dataOfRowId.showId || !dataOfRowId.companyId) {
      console.error("showId or companyId is missing.");
      return;
    }
    const url = `${baseUrl}/api/leader/close-lead/${dataOfRowId.showId}/${dataOfRowId.companyId}`;
    try {
      const response = await axios.put(url, dataOfRowId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Update successful:", response);
      setOverLayStatusActive(false);
      if (isAssignLeadesActive) {
        fetchAssignedLeads(selectedDate);
      } else if (isCallbackLeadesActive) {
        fetchCallbackLeads(selectedCallbackDate);
      }
      setPopUpActive(false);
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleopenUpdateSubmit = () => {
    setOverLayStatusActive(true);
  };

  const handlePopUpOpen = (
    showId,
    companyId,
    showName,
    companyName,
    timeZone,
    Status,
    closerNote,
    rejectionReason,
    Notes,
    ContactName,
    dmRating,
    Email,
    daysToCall,
    lastContact,
    closingDate,
    Hotel,
    Budget,
    Rooms,
    CheckIn,
    CheckOut,
    roomStay,
    totalStay,
    LeadDate,
    EmailType
  ) => {
    setPopUpActive(true);
    setDataOfRowId({
      showId,
      companyId,
      Show: showName,
      Company: companyName,
      Timezone: timeZone,
      Status,
      "Closer Note": closerNote,
      "Rejection Reason": rejectionReason,
      Notes,
      "Contact Name": ContactName,
      "DM Rating": dmRating,
      Email,
      "Days to Call": daysToCall,
      "Last Contact": lastContact,
      "Closing Date": closingDate,
      Hotel,
      Budget,
      Rooms,
      "Check-in": CheckIn,
      "Check-out": CheckOut,
      "/ Room Stay": roomStay,
      "Total Stay": totalStay,
      "Lead Date": LeadDate,
      "Email Type": EmailType,
    });
  };

  return (
    <div className="sales-manager-container">
      <div className="main-header-info">
        <h2 className="main-header-h2">Sales Manager View</h2>
      </div>
      <div className="main-sales-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showTeam} className="sidebar-btn">
                View Team
              </button>
              <button onClick={showAssignedLeads} className="sidebar-btn">
                Assigned Leads
              </button>
              <button onClick={showCallbackLeads} className="sidebar-btn">
                Callback Leads
              </button>
              <button onClick={showAccessLeads} className="sidebar-btn">
                Access Leads
              </button>
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {isViewTeamActive && (
          <div className="table-container">
            <h2 className="table-title">Team Members</h2>
            {/* <SalesManagerTeamTable /> */}
            <AllAgents />
          </div>
        )}

        {isAssignLeadesActive && (
          <div className="table-container">
            <h2 className="table-title">Assigned Leads</h2>
            <AssignedLeadsTable
              assignedLeads={assignedLeads}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
              handlePopUpOpen={handlePopUpOpen}
              isLoading={isLoading}
            />
          </div>
        )}

        {isCallbackLeadesActive && (
          <div className="table-container">
            <h2 className="table-title">Callback Leads</h2>
            <CallbackLeadsTable
              callbackLeads={callbackLeads}
              handlePopUpOpen={handlePopUpOpen}
              isLoading={isLoading}
              setSelectedDate={setSelectedCallbackDate}
              selectedDate={selectedCallbackDate}
            />
          </div>
        )}

        {isAccessLeadsActive && (
          <div className="existing-leads-container">
            <h2 className="table-title">Existing Leads</h2>
            <ExistingLeads />
          </div>
        )}

        {popUpActive && (
          <TeamLeaderPopup
            dataOfRowId={dataOfRowId}
            setDataOfRowId={setDataOfRowId}
            overLayStatusActive={overLayStatusActive}
            handleopenUpdateSubmit={handleopenUpdateSubmit}
            handleCloseUpdateSubmit={handleCloseUpdateSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default SalesManager;
