import React, { useEffect, useState } from "react";
import RequestTimeZone from "../RequestTimeZone/RequestTimeZone";
import "./NewLeads.css";

const NewLeads = ({
  selectOptionsTimezone,
  selectedTimeZone,
  setSelectedTimeZone,
  fetchShows,
  leadsCount,
}) => {
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const [breakTimer, setBreakTimer] = useState(0);
  const [isBreakTimerRunning, setIsBreakTimerRunning] = useState(false);

  useEffect(() => {
    // Initialize login timer
    const storedStartTime = localStorage.getItem("startTime");
    if (storedStartTime) {
      const elapsedTime = Date.now() - storedStartTime;
      setTimer(elapsedTime);
      setIsTimerRunning(true);
    } else {
      setIsTimerRunning(true);
      localStorage.setItem("startTime", Date.now());
    }

    // Initialize break timer
    const storedBreakStartTime = localStorage.getItem("breakStartTime");
    const storedBreakTimer = localStorage.getItem("breakTimer");
    if (storedBreakStartTime) {
      const elapsedBreakTime = Date.now() - storedBreakStartTime;
      setBreakTimer(
        (storedBreakTimer ? +storedBreakTimer : 0) + elapsedBreakTime
      );
      setIsBreakTimerRunning(true);
    } else if (storedBreakTimer) {
      setBreakTimer(+storedBreakTimer); // Restore break timer even if it's not running
    }

    const interval = setInterval(() => {
      if (isTimerRunning) {
        setTimer((prevTime) => prevTime + 1000);
      }
      if (isBreakTimerRunning) {
        setBreakTimer((prevBreakTime) => prevBreakTime + 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerRunning, isBreakTimerRunning]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  const startBreak = () => {
    if (!isBreakTimerRunning) {
      localStorage.setItem("breakStartTime", Date.now());
      setIsBreakTimerRunning(true);
    }
  };

  const endBreak = () => {
    if (isBreakTimerRunning) {
      const storedBreakStartTime = localStorage.getItem("breakStartTime");
      if (storedBreakStartTime) {
        const elapsedBreakTime = Date.now() - storedBreakStartTime;
        const totalBreakTime = breakTimer;
        setBreakTimer(totalBreakTime);
        localStorage.setItem("breakTimer", totalBreakTime);
      }
      localStorage.removeItem("breakStartTime");
      setIsBreakTimerRunning(false);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", background: "#fff" }}>
      <RequestTimeZone
        selectOptionsTimezone={selectOptionsTimezone}
        selectedTimeZone={selectedTimeZone}
        setSelectedTimeZone={setSelectedTimeZone}
      />
      {/* <div className="timer-container">
        <span className="timer-label">Login Time</span>
        <p className="timer">{formatTime(timer)}</p>
      </div>
      <div className="timer-container">
        <span className="timer-label">Break Time</span>
        <p className="timer">{formatTime(breakTimer)}</p>
      </div>
      <button
        onClick={startBreak}
        disabled={isBreakTimerRunning} // Disable if break timer is already running
        className="start-break-button"
      >
        Start Break
      </button>
      <button
        onClick={endBreak}
        disabled={!isBreakTimerRunning} // Disable if break timer is not running
        className="end-break-button"
      >
        End Break
      </button> */}
      <button
        onClick={fetchShows}
        disabled={leadsCount > 0 || !selectedTimeZone}
        className="fetch-data-button"
      >
        <p> Get More Leads</p>
      </button>
    </div>
  );
};

export default NewLeads;
