import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import "./LocatorTable.css";
import { baseUrl } from "../../api/Api";
import { useNavigate } from "react-router-dom";
import UploadLocatorsSection from "../uploadControllers/UploadLocatorSection";
import UploadLeadsToShow from "./UploadLeadsToShow";
import { ShowChart, Visibility } from "@mui/icons-material";

const LocatorTable = ({ data }) => {
  const [editingCell, setEditingCell] = useState(null);
  const [locatorData, setLocatorData] = useState(data || []);
  const [editableValue, setEditableValue] = useState("");
  const [selectedEditionYear, setSelectedEditionYear] = useState("");
  const [viewUploadSection, setViewUploadSection] = useState(false);
  const [selectedShowId, setSelectedShowId] = useState(null);
  const [selectedShow, setSelectedShow] = useState(null);

  const [tempValue, setTempValue] = useState({});
  const [showLeads, setShowLeads] = useState([]);
  const [dublicatedLeads, setDublicatedLeads] = useState([]);
  const { token, role } = useContext(AuthContext);
  const cellRefs = useRef({});
  const navigate = useNavigate();

  useEffect(() => {
    setLocatorData(data || []);
  }, [data]);

  const columnsOfTable = [
    ...(role === "locator manager"
      ? [{ field: "View", headerName: "View" }]
      : []),
    { field: "Show", headerName: "Show" },
    { field: "isActive", headerName: "Active" },
    { field: "notes", headerName: "Notes" },

    { field: "Next Dates", headerName: "Next Dates" },
    { field: "End Date", headerName: "End Date" },
    { field: "Duration", headerName: "Duration" },
    {
      field: "Convention Center / Host Hotel",
      headerName: "Convention Center / Host Hotel",
    },
    { field: "City, Country", headerName: "City State" },
    { field: "Venue", headerName: "Venue" },
    { field: "description", headerName: "Description" },
    { field: "similarShows", headerName: "Similar Shows" },

    { field: "totalLeads", headerName: "Total Count" },
    { field: "newLeads", headerName: "New Count" },
    { field: "assignedLeads", headerName: "In Works" },
    { field: "doneWork", headerName: "Done Count" },
    { field: "Show Url", headerName: "Show Url" },
    { field: "HQ", headerName: "HQ" },
    { field: "HQ Price Sky/Conv", headerName: "HQ Price Sky/Conv" },
    { field: "Locate By", headerName: "Locate By" },

    { field: "Platform", headerName: "Platform" },
    { field: "Exhibitor List", headerName: "Exhibitor List" },
    { field: "Housing Link", headerName: "Housing Link" },
    { field: "Locate Date", headerName: "Locate Date" },
    { field: "Stage", headerName: "Stage" },
    { field: "Pricing Status", headerName: "Pricing Status" },
    { field: "Lead Status", headerName: "Lead Status" },
  ];

  const locateStatusOptions = [
    "Approved",
    "Not Started",
    "No Lead List",
    "Out Of Date",
  ];

  const pricingStatusOptions = ["Low", "High"];
  const isActiveOptions = ["Yes", "No"];

  const handleEditionYearChange = (event) => {
    setSelectedEditionYear(event.target.value);
  };

  // const handleCellClick = (locatorId, field, value) => {
  //   if (field !== "Show Url" && field !== "Date Modified") {
  //     setEditingCell({ locatorId, field });
  //     setEditableValue(value);
  //     setTimeout(() => {
  //       if (cellRefs.current[`${locatorId}-${field}`]) {
  //         cellRefs.current[`${locatorId}-${field}`].focus();
  //       }
  //     }, 0);
  //   }
  // };

  const handleCellClick = (locatorId, field, value) => {
    console.log(field);

    // Update the condition to prevent editing of all cells
    if (field === "isActive") {
      // This will never be true, hence no cell will be editable

      setEditingCell({ locatorId, field });
      setEditableValue(value);
      setTimeout(() => {
        if (cellRefs.current[`${locatorId}-${field}`]) {
          cellRefs.current[`${locatorId}-${field}`].focus();
        }
      }, 0);
    }
    if (
      field === "Show" ||
      field === "Convention Center / Host Hotel" ||
      field === "Venue" ||
      field === "notes" ||
      field === "City, Country" ||
      field === "Show Url"
    ) {
      console.log(value);

      setEditingCell({ locatorId, field });
      setEditableValue(value);
      setTimeout(() => {
        if (cellRefs.current[`${locatorId}-${field}`]) {
          cellRefs.current[`${locatorId}-${field}`].focus();
        }
      }, 0);
    }
  };

  const handleBlur = async (locatorId, field, newValue) => {
    console.log(newValue);

    // Find the locator with the given locatorId
    const updatedLocator = locatorData.find(
      (locator) => locator._id === locatorId
    );

    if (updatedLocator) {
      // Update the specific field with the new value in the locator
      updatedLocator[field] = newValue;

      const payload = { ...updatedLocator };
      delete payload._id;

      try {
        await axios.put(
          `${baseUrl}/api/locator/update-locator/${locatorId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update the state with the modified data
        setLocatorData((prevData) =>
          prevData.map((locator) =>
            locator._id === locatorId
              ? { ...locator, ...updatedLocator }
              : locator
          )
        );

        setEditingCell(null);
      } catch (error) {
        console.error("Error updating cell:", error);
      }
    } else {
      console.error(`Locator with id ${locatorId} not found.`);
    }
  };

  const handleDropdownChange = (locatorId, field, newValue) => {
    // Update the specific field value in the state
    setLocatorData(
      locatorData.map((locator) =>
        locator._id === locatorId ? { ...locator, [field]: newValue } : locator
      )
    );

    // Update editableValue
    setEditableValue(newValue);

    // Trigger save action via handleBlur
    handleBlur(locatorId, field, newValue);
  };

  const handleChange = (event) => {
    setEditableValue(event.target.value);
    console.log(event.target.value);
  };

  // Filter shows based on the selected edition year
  const filteredShows = selectedEditionYear
    ? locatorData.filter((show) => show.edition === selectedEditionYear)
    : [];

  console.log(locatorData);

  // Get unique edition years for the dropdown
  const editionYears = Array.from(
    new Set(locatorData.map((show) => show.edition))
  );

  const handleShowRowClick = (leads, show, showId) => {
    const fetchLeadsOfTheShow = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/locator/leads/${showId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowLeads(response.data); // Update the state with the fetched leads

        // Only navigate after the leads have been fetched
        const encodedShow = encodeURIComponent(show);
        navigate(`/leads-of-show/${encodedShow}`, {
          state: { showLeads: response.data, show, showId }, // Use the fetched data here
        });
      } catch (error) {
        console.error(`Error fetching Leads of ${show}:`, error);
      }
    };

    fetchLeadsOfTheShow(); // Call the async function
  };

  const handleViewDublicaedLeads = (show, showId) => {
    const fetchDublicatedLeadsOfTheShow = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/locator/duplicated/${showId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setDublicatedLeads(response.data); 

        // Only navigate after the leads have been fetched
        const encodedShow = encodeURIComponent(show);
        navigate(`/leads-of-show/${encodedShow}`, {
          state: { showLeads: response.data, show, showId , type:"dublicated" }, 
        });
      } catch (error) {
        console.error(`Error fetching Leads of ${show}:`, error);
      }
    };

    fetchDublicatedLeadsOfTheShow();
  };

  const handleViewUploadSection = (Show, showId) => {
    setSelectedShowId(showId);
    setSelectedShow(Show);
    setViewUploadSection(true);
  };

  const handleCloseUploadSection = () => {
    setViewUploadSection(false);
  };

  return (
    <div className="table-container">
      {/* Dropdown to filter by Edition Year */}
      <FormControl
        variant="outlined"
        sx={{ width: "300px", marginBottom: "1rem" }}
      >
        <InputLabel>Edition Year</InputLabel>
        <Select
          value={selectedEditionYear}
          onChange={handleEditionYearChange}
          label="Edition Year"
        >
          {editionYears.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Table to display the filtered shows */}
      <Table>
        <TableHead>
          <TableRow>
            {columnsOfTable.map((column) => (
              <TableCell key={column.field} sx={{ color: "wheat" }}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredShows.map((show) => (
            <TableRow key={show._id} style={{ height: "75px" }}>
              {columnsOfTable.map((column) => (
                <TableCell
                  key={column.field}
                  onClick={() =>
                    column.field !== "View" &&
                    handleCellClick(
                      show._id,
                      column.field,
                      show[column.field] || ""
                    )
                  }
                >
                  {column.field === "View" ? (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <button
                        onClick={() =>
                          handleShowRowClick(show.leads, show.Show, show._id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        View Leads
                      </button>

                      <button
                        onClick={() =>
                          handleViewDublicaedLeads(show.Show, show._id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Dublicated
                      </button>
                      <button
                        onClick={() =>
                          handleViewUploadSection(show.Show, show._id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        Upload
                      </button>
                    </div>
                  ) : column.field === "totalLeads" ? (
                    // Show the Tooltip with the timezones count on hover
                    <Tooltip
                      title={
                        <div>
                          <strong style={{ fontSize: "large" }}>
                            Timezones Count:
                          </strong>
                          <ul>
                            {Object.entries(show.timezonesCount).map(
                              ([timezone, count]) => (
                                <li
                                  key={timezone}
                                  style={{ fontSize: "medium" }}
                                >
                                  {timezone}: {count}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      }
                      arrow
                    >
                      <span style={{ cursor: "pointer" }}>
                        {show.totalLeads}
                      </span>
                    </Tooltip>
                  ) : editingCell?.locatorId === show._id &&
                    editingCell.field === column.field ? (
                    column.field === "Locate Status" ? (
                      <FormControl
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        fullWidth
                        sx={{ width: "140px" }}
                      >
                        <InputLabel>Locate Status</InputLabel>
                        <Select
                          value={editableValue}
                          onChange={(event) =>
                            handleDropdownChange(
                              show._id,
                              "Locate Status",
                              event.target.value
                            )
                          }
                          onBlur={() =>
                            handleBlur(show._id, column.field, editableValue)
                          }
                          label="Locate Status"
                        >
                          {locateStatusOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : column.field === "Pricing Status" ? (
                      <FormControl
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        fullWidth
                        sx={{ width: "140px" }}
                      >
                        <InputLabel>Pricing Status</InputLabel>
                        <Select
                          value={editableValue}
                          onChange={(event) =>
                            handleDropdownChange(
                              show._id,
                              "Pricing Status",
                              event.target.value
                            )
                          }
                          onBlur={() =>
                            handleBlur(show._id, column.field, editableValue)
                          }
                          label="Pricing Status"
                        >
                          {pricingStatusOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : column.field === "Show" ? (
                      <TextField
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        fullWidth
                        defaultValue={show[column.field] || ""}
                        sx={{ width: "140px" }}
                        onFocus={() =>
                          setEditableValue(show[column.field] || "")
                        }
                        onBlur={(event) => {
                          const finalValue = event.target.value;
                          handleBlur(show._id, column.field, finalValue);
                        }}
                      ></TextField>
                    ) : column.field === "notes" ? (
                      <TextField
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        defaultValue={show[column.field] || ""}
                        fullWidth
                        sx={{ width: "140px" }}
                        onBlur={(event) => {
                          const finalValue = event.target.value;
                          handleBlur(show._id, column.field, finalValue);
                        }}
                      ></TextField>
                    ) : column.field === "Convention Center / Host Hotel" ? (
                      <TextField
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        fullWidth
                        defaultValue={show[column.field] || ""}
                        sx={{ width: "140px" }}
                        onBlur={(event) => {
                          const finalValue = event.target.value;
                          handleBlur(show._id, column.field, finalValue);
                        }}
                      ></TextField>
                    ) : column.field === "Venue" ? (
                      <TextField
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        fullWidth
                        defaultValue={show[column.field] || ""}
                        sx={{ width: "140px" }}
                        onBlur={(event) => {
                          const finalValue = event.target.value;
                          handleBlur(show._id, column.field, finalValue);
                        }}
                      ></TextField>
                    ) : column.field === "City, Country" ? (
                      <TextField
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        defaultValue={show[column.field] || ""}
                        fullWidth
                        sx={{ width: "140px" }}
                        onBlur={(event) => {
                          const finalValue = event.target.value;
                          handleBlur(show._id, column.field, finalValue);
                        }}
                      ></TextField>
                    ) : column.field === "Show Url" ? (
                      <TextField
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        defaultValue={show[column.field] || ""}
                        fullWidth
                        sx={{ width: "140px" }}
                        onBlur={(event) => {
                          const finalValue = event.target.value;
                          handleBlur(show._id, column.field, finalValue);
                        }}
                      ></TextField>
                    ) : column.field === "isActive" ? (
                      <FormControl
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        fullWidth
                        defaultValue={show[column.field] || ""}
                        sx={{ width: "140px" }}
                      >
                        <InputLabel>Is Active</InputLabel>
                        <Select
                          value={editableValue}
                          onChange={(event) =>
                            handleDropdownChange(
                              show._id,
                              "isActive",
                              event.target.value === "Yes" // Convert to boolean for isActive
                            )
                          }
                          onBlur={() =>
                            handleBlur(show._id, column.field, editableValue)
                          }
                          label="Is Active"
                        >
                          {isActiveOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <TextField
                        variant="outlined"
                        ref={(el) =>
                          (cellRefs.current[`${show._id}-${column.field}`] = el)
                        }
                        value={editableValue}
                        onChange={handleChange}
                        onBlur={() =>
                          handleBlur(show._id, column.field, editableValue)
                        }
                        fullWidth
                      />
                    )
                  ) : column.field === "isActive" ? (
                    // Conditional rendering for "isActive" field
                    show.isActive ? (
                      "Yes"
                    ) : (
                      "No"
                    )
                  ) : column.field === "similarShows" ? (
                    show[column.field] && show[column.field].length > 0 ? (
                      <div className="similar-shows-container">
                        {/* MUI Icon */}
                        <Visibility
                          style={{ cursor: "pointer", color: "#007bff" }}
                        />

                        {/* The ordered list will be shown on hover */}
                        <div className="similar-shows-list">
                          <ol>
                            {show[column.field].map((similarShow) => (
                              <li key={similarShow._id || similarShow.showName}>
                                {similarShow.showName}
                              </li>
                            ))}
                          </ol>
                        </div>
                      </div>
                    ) : (
                      "No similar shows available"
                    )
                  ) : column.field === "End Date" ||
                    column.field === "Locate Date" ||
                    column.field === "House Recycle Date" ||
                    column.field === "Floor Recycle Date" ? (
                    show[column.field]
                  ) : (
                    show[column.field]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {viewUploadSection && (
        <div className="modal-backdrop">
          <div className="upload-modal-content">
            <UploadLeadsToShow Show={selectedShow} showId={selectedShowId} />
            <button onClick={handleCloseUploadSection}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocatorTable;
