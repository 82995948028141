import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import cityState from "../../utils/Timezones";

const editionYears = ["2024", "2025", "2026"];

const LocatorPopup = ({
  formDataLocator,
  activeShowName,
  handleChangedata,
  handleButtonClick,
  handleClosePopup,
  locatedShows,
  handleLocatedShowClick,
}) => {
  // const allShows = locatedShows.reduce((acc, show) => {
  //   // Add the original show
  //   acc.push({ ...show, type: "Original Show" });

  //   // Add similar shows with data inherited from the original show (excluding showName)
  //   if (show.similarShows) {
  //     acc = acc.concat(
  //       show.similarShows.map((similarShow) => ({
  //         ...similarShow,
  //         ...Object.fromEntries(
  //           Object.entries(show).filter(([key]) => key !== "showName")
  //         ),
  //         type: "Similar Show",
  //       }))
  //     );
  //   }

  //   return acc;
  // }, []);

  const [selectedShow, setSelectedShow] = useState(activeShowName || "");

  useEffect(() => {
    if (activeShowName) {
      setSelectedShow(activeShowName);

      // Find the data for the active show in locatedShows
      const activeShowData = locatedShows.find(
        (show) => show.rawName === activeShowName
      );

      if (activeShowData) {
        // Populate the form with the active show data
        handleLocatedShowClick(activeShowData);
      }
    }
  }, []);

  const handleShowSelect = (event) => {
    const selectedShowName = event.target.value;
    setSelectedShow(selectedShowName);

    // Find the selected show data
    const selectedShowData = locatedShows.find(
      (show) => show.rawName === selectedShowName
    );

    if (selectedShowData) {
      console.log(selectedShowData);

      // Use handleLocatedShowClick to populate form data
      handleLocatedShowClick(selectedShowData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidDateFormat = (date) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(date);
    };

    const isEmpty = Object.values(formDataLocator).some(
      (value) => value === "" || value === null || value === undefined
    );

    if (isEmpty) {
      alert("Please fill in all fields before submitting.");
      return;
    }

    if (
      !isValidDateFormat(formDataLocator["Next Dates"]) ||
      !isValidDateFormat(formDataLocator["End Date"])
    ) {
      alert(
        "Please provide valid dates for 'Next Dates' and 'End Date' in the format YYYY-MM-DD."
      );
      return;
    }

    handleButtonClick();
  };

  return (
    <div className="answerPopUp-container">
      <div className="popup-info-contain">
        <div className="popup-input-contain" style={{ marginBottom: "1rem" }}>
          <TextField
            name="Use Located Show Data"
            select
            label="Use Located Show Data"
            fullWidth
            style={{ width: "100%" }}
            value={selectedShow}
            onChange={handleShowSelect}
          >
            {locatedShows.map((show, index) => (
              <MenuItem key={index} value={show.rawName}>
                {show.showName}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <form onSubmit={handleSubmit} className="popup-inputs-container">
          <div className="popup-input-contain">
            <TextField
              name="edition"
              select
              label="Select Edition"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator.edition}
              onChange={handleChangedata}
              required
            >
              {editionYears.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="popup-input-contain">
            <TextField
              id="Show"
              label="Show Name"
              variant="outlined"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator.Show}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <TextField
              id="Show Url"
              label="Show Url"
              variant="outlined"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator["Show Url"]}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <TextField
              id="Next Dates"
              label="Next Dates"
              variant="outlined"
              type="date"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator["Next Dates"]}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <TextField
              id="End Date"
              label="End Date"
              variant="outlined"
              type="date"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator["End Date"]}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <TextField
              id="Duration"
              label="Duration"
              variant="outlined"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator.Duration}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <TextField
              id="Convention Center / Host Hotel"
              label="Recommended"
              variant="outlined"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator["Convention Center / Host Hotel"]}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator["description"]}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <label
              htmlFor="similarShows"
              style={{
                fontWeight: "bold",
                marginBottom: "8px",
                display: "block",
              }}
            >
              Similar Shows
            </label>
            <ol id="similarShows" style={{ paddingLeft: "20px" }}>
              {formDataLocator.similarShows.map((show, index) => (
                <li key={index}>{show.showName}</li>
              ))}
            </ol>
          </div>
          <div className="popup-input-contain">
            <TextField
              id="Venue"
              label="Venue"
              variant="outlined"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator.Venue}
              onChange={handleChangedata}
              required
            />
          </div>
          <div className="popup-input-contain">
            <TextField
              name="City, Country"
              select
              label="Select City, Country"
              fullWidth
              style={{ width: "100%" }}
              value={formDataLocator["City, Country"]}
              onChange={handleChangedata}
              required
            >
              {cityState.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </form>
        <div>
          <div className="popup-submit-btn">
            <button type="submit" onClick={handleSubmit}>
              Submit
            </button>
            <button onClick={handleClosePopup}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocatorPopup;
