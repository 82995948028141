import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  MenuItem,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import { format } from "date-fns";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";

const RawShowLeads = () => {
  const [leads, setLeads] = useState([]);
  const [statusCounts, setStatusCounts] = useState({});
  const [showName, setShowName] = useState("");
  const [showId, setShowId] = useState("");
  const [type, setType] = useState("");
  const [showType, setShowType] = useState("");

  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedShowId, setSelectedShowId] = useState("");
  const [shows, setShows] = useState([]);
  const { token } = useContext(AuthContext);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.showLeads) {
      setLeads(location.state.showLeads);
      setShowName(location.state.show);
      setShowId(location.state.showId);
      setType(location.state.type);
      setShowType(location.state.showType);

      // Calculate status counts
      const counts = location.state.showLeads.reduce((acc, lead) => {
        acc[lead.Status] = (acc[lead.Status] || 0) + 1;
        return acc;
      }, {});
      setStatusCounts(counts);
    }
  }, [location.state]);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM dd, yyyy h:mm a");
  };

  console.log(leads);

  // Handle deleting a lead
  const handleDeleteLead = async (companyId) => {
    let url;
    if (showType === "generated") {
      url = `${baseUrl}/api/locator/delete/generated-rawShow/${showId}/company/${companyId}`;
    } else {
      url = `${baseUrl}/api/locator/delete/rawShow/${showId}/company/${companyId}`;
    }
    try {
      // Make the DELETE request to remove the lead
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setLeads((prevLeads) => {
          // Filter out the deleted lead
          const updatedLeads = prevLeads.filter(
            (lead) => lead._id !== companyId
          );

          // Check if only one lead remains after deletion
          if (updatedLeads.length === 1) {
            // Remove the last remaining lead
            return [];
          }

          return updatedLeads;
        });
      }
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
  };

  return (
    <>
      <Box mb={2}></Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Controllers</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Show</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Lead</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Phone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Email</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Timezone</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>City, State</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Link</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Created At</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Matched</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <Typography variant="h6">
                    There are no duplicated leads
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              leads.map((lead) => (
                <TableRow key={lead._id}>
                  <TableCell>
                    {type === "dublicated" && (
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteLead(lead._id)}
                      >
                        Delete
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{showName}</TableCell>
                  <TableCell>{lead.companyName}</TableCell>
                  <TableCell>{lead.phone}</TableCell>
                  <TableCell>{lead.email}</TableCell>
                  <TableCell>{lead.timezone}</TableCell>
                  <TableCell>{lead.cityState}</TableCell>
                  <TableCell>{lead.link}</TableCell>
                  <TableCell>{lead.createdAt}</TableCell>
                  <TableCell>{lead.matched ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RawShowLeads;
