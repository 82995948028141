import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { format } from "date-fns"; // Importing the format function
import { baseUrl } from "../../api/Api";

const OperationReports = () => {
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  

  const formatDecimalToTime = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setLoading(true); // Start loading before the API call
        const response = await axios.get(
          `${baseUrl}/api/locator/user-report/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data.result; // Assuming the response contains a `result` field
        console.log(data);

        // Modify data to include missing status counts
        const updatedReports = data.map((report) => {
          

          // Format loggedinTime and loggedoutTime to human-readable format
          const formattedLoggedinTime = format(
            new Date(report.loggedinTime),
            "hh:mm a"
          );
          const formattedLoggedoutTime = format(
            new Date(report.loggedoutTime),
            "hh:mm a"
          );

          const formattedTotalHours = formatDecimalToTime(report.totalHours);
          const formattedBreakTime = formatDecimalToTime(report.breakTime);

          return {
            ...report,
            formattedLoggedinTime,
            formattedLoggedoutTime,
            formattedTotalHours,
            formattedBreakTime,
          };
        });

        setReports(updatedReports);
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        console.error("Error fetching reports:", error);
        setError("Failed to fetch reports. Please try again.");
        setLoading(false); // Stop loading in case of error
      }
    };

    fetchReports(); // Call the function when component mounts or `id` or `token` changes
  }, [id, token]); // Dependencies: re-fetch when `id` or `token` changes

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <TableContainer sx={{ padding: 2, borderRadius: "8px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Date</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Name</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Login Time</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Logout Time</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Total Hours</strong>
              </TableCell>
              <TableCell sx={{ color: "wheat" }}>
                <strong>Break Time</strong>
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.date}>
                <TableCell>{report.date}</TableCell>
                <TableCell>{report.userName}</TableCell>
                <TableCell>{report.formattedLoggedinTime}</TableCell>
                <TableCell>{report.formattedLoggedoutTime}</TableCell>
                <TableCell>{report.formattedTotalHours}</TableCell>
                <TableCell>{report.formattedBreakTime}</TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OperationReports;

