import React, { useState, useEffect } from "react";

const statusOptions = [
  { label: "New", value: "New" },
  { label: "VM", value: "VM" },
  { label: "Callback", value: "Callback" },
  { label: "GK Blocked", value: "GK Blocked" },
  { label: "DM Rejected", value: "DM Rejected" },
  { label: "Do Not Call", value: "Do Not Call" },
  { label: "Email Only", value: "Email Only" },
  { label: "Email Follow-up", value: "Email Follow-up" },
  { label: "Waiting Approval", value: "Waiting Approval" },
  { label: "Prospect", value: "Prospect" },
  { label: "Flagged", value: "Flagged" },
  { label: "N/A", value: "N/A" },
  { label: "Redialing", value: "Redialing" },
  { label: "Closed Won", value: "Closed Won" },
  { label: "Closed Lost", value: "Closed Lost" },
];

const OverlayStatus = ({
  handleCloseUpdateSubmit,
  dataOfRowId,
  setDataOfRowId,
  sendEmailRequest,
  handleCloseStatusPopup,
  addToBlackList,
}) => {
  const [nextContactDate, setNextContactDate] = useState(
    dataOfRowId["Next Contact"] || ""
  );
  const [error, setError] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(
    dataOfRowId.Status || ""
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDataOfRowId((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "Status") {
      setSelectedStatus(value);
    }
  };

  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setNextContactDate(dateValue);
    setDataOfRowId((prevData) => ({
      ...prevData,
      "Next Contact": dateValue,
    }));
  };

  const handleSubmit = () => {
    if (
      [
        "Callback",
        "VM",
        "Email Follow-up",
        "Waiting Approval",
        "Prospect",
        "Flagged",
      ].includes(selectedStatus) &&
      !nextContactDate
    ) {
      setError("Next Contact Date is required for status.");
      return;
    }

    // if (selectedStatus === "Callback" && !nextContactDate) {
    //   setError("Next Contact Date is required for Callback status.");
    //   return;
    // }
    setError("");
    handleCloseUpdateSubmit();
    // sendEmailRequest()
  };

  const formattedToday = new Date().toISOString().split("T")[0];

  return (
    <div className="overlayStatus-container">
      <div className="overlay-status-contain">
        <button onClick={addToBlackList}>Add To Blacklist</button>
        <div className="overlay-status-control">
          <label htmlFor="Status">Status:</label>
          <select
            name="Status"
            id="Status"
            className="overlay-input"
            value={selectedStatus}
            onChange={handleInputChange}
          >
            <option value="" hidden>
              Select a Status ...
            </option>
            {statusOptions.map((optionItem) => (
              <option key={optionItem.value} value={optionItem.value}>
                {optionItem.label}
              </option>
            ))}
          </select>
        </div>

        {/* Conditionally render the "Next Contact" field only when "Callback" is selected */}
        {[
          "Callback",
          "VM",
          "Email Follow-up",
          "Waiting Approval",
          "Prospect",
          "Flagged",
        ].includes(selectedStatus) && (
          <div className="overlay-status-control">
            <label htmlFor="nextContactDate">Next Contact:</label>
            <input
              type="date"
              id="nextContactDate"
              name="nextContactDate"
              className="tab-input next-contact-input"
              value={nextContactDate}
              onChange={handleDateChange}
              min={formattedToday}
            />
          </div>
        )}
        <div style={{ display: "flex", gap: "30px" }}>
          <button
            className="overlay-status-btn"
            type="button"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            className="overlay-status-btn"
            type="button"
            onClick={handleCloseStatusPopup}
          >
            Close
          </button>
        </div>

        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default OverlayStatus;

// import React, { useState, useEffect } from "react";

// const statusOptions = [
//   { label: "New", value: "New" },
//   { label: "VM", value: "VM" },
//   { label: "Callback", value: "Callback" },
//   { label: "GK Blocked", value: "GK Blocked" },
//   { label: "DM Rejected", value: "DM Rejected" },
//   { label: "Do Not Call", value: "Do Not Call" },
//   { label: "Email Only", value: "Email Only" },
//   { label: "Email Follow-up", value: "Email Follow-up" },
//   { label: "Waiting Approval", value: "Waiting Approval" },
//   { label: "Prospect", value: "Prospect" },
//   { label: "Flagged", value: "Flagged" },
//   { label: "N/A", value: "N/A" },
//   { label: "Closed Won", value: "Closed Won" },
//   { label: "Closed Lost", value: "Closed Lost" },
// ];

// const OverlayStatus = ({handleCloseUpdateSubmit,dataOfRowId,setDataOfRowId}) => {
//   const [nextContactDate, setNextContactDate] = useState('');
//   const [nextContactTime, setNextContactTime] = useState('');

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setDataOfRowId((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleDateChange = (e) => {
//     setNextContactDate(e.target.value);
//   };

//   const handleTimeChange = (e) => {
//     setNextContactTime(e.target.value);
//   };

//   useEffect(() => {
//     console.log("Component mounted");
//     const now = new Date();
//     console.log("Current Date and Time:", now.toString());

//     console.log("Next Contact Date:", nextContactDate);
//     console.log("Next Contact Time:", nextContactTime);
//   }, [nextContactDate, nextContactTime]);

//   return (
//     <div className="overlayStatus-container">
//       <div className="overlay-status-contain">
//         <div className="overlay-status-control">
//           <label htmlFor="Status">Status:</label>
//           <select
//             name="Status"
//             id="Status"
//             className="overlay-input"
//             value={dataOfRowId.Status || ""}
//             onChange={handleInputChange}
//           >
//             <option value="" hidden>
//               Select a Status ...
//             </option>
//             {statusOptions.map((optionItem) => (
//               <option key={optionItem.value} value={optionItem.value}>
//                 {optionItem.label}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="overlay-status-control">
//           <label htmlFor="nextContactDate">Next Contact Date:</label>
//           <input
//             type="date"
//             id="nextContactDate"
//             name="nextContactDate"
//             className="overlay-input"
//             value={nextContactDate}
//             onChange={handleDateChange}
//           />
//         </div>
//         <div className="overlay-status-control">
//           <label htmlFor="nextContactTime">Next Contact Time:</label>
//           <input
//             type="time"
//             id="nextContactTime"
//             name="nextContactTime"
//             className="overlay-input"
//             value={nextContactTime}
//             onChange={handleTimeChange}
//           />
//         </div>

//         <button
//           className="overlay-status-btn"
//           type="submit"
//           onClick={handleCloseUpdateSubmit}
//         >
//           Submit
//         </button>
//       </div>
//     </div>
//   );
// };

// export default OverlayStatus;
