import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import { TextField, MenuItem, Select, Button } from "@mui/material";
import "./PricingTable.css";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";
import CreateHotelInMatchedPricing from "./pricingJunior/CreateHotelInMatchedPricing";

const PricingTable = ({ data, fetchData }) => {
  const [editingCell, setEditingCell] = useState(null);
  const [pricingData, setPricingData] = useState(data || []);
  const [editableValue, setEditableValue] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [editingHotel, setEditingHotel] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedPricingStatus, setSelectedPricingStatus] = useState("");
  const [selectedPricingId, setSelectedPricingId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { token, role } = useContext(AuthContext);
  const cellRefs = useRef({});
  console.log(data);

  useEffect(() => {
    setPricingData(data || []);
  }, [data]);

  const allColumns = [
    { field: "Actions", headerName: "Actions" },
    { field: "Approve", headerName: "Approve Pricing" },
    { field: "Show", headerName: "Show" },
    { field: "Pricing Status", headerName: "Pricing Status" },
    { field: "MadeBy", headerName: "Made By" },
    { field: "Min Budget", headerName: "Min Budget" },
    { field: "Min Distance", headerName: "Min Distance" },
    { field: "Min +- Max", headerName: "Min ± Max" },
    { field: "Top Rated", headerName: "Top Rated" },
    { field: "isExpired", headerName: "Expired" },
    { field: "lastModified", headerName: "Date Modified" },
  ];

  const columnsOfTable =
    role !== "pricing junior"
      ? allColumns
      : allColumns.filter((column) => column.field !== "SellingPrice");

  const handleCellClick = (pricingId, field, value) => {
    if (
      field !== "Shows" &&
      field !== "Min Distance" &&
      field !== "Min +- Max" &&
      field !== "Min Budget" &&
      field !== "MadeBy" &&
      field !== "isExpired" &&
      field !== "lastModified"
    ) {
      console.log(pricingId, field);

      setEditingCell({ pricingId, field });
      setEditableValue(value);
    }
  };

  const handleHotelCellClick = (pricingId, hotelId, field, value) => {
    if (
      field === "SellingPrice" ||
      field === "Online Price" ||
      field === "Actual Price" ||
      field === "Distance"
    ) {
      setEditingHotel({ hotelId, pricingId });
      setEditingCell({ hotelId, field });
      setEditableValue(value);
      setTimeout(() => {
        if (cellRefs.current[`hotel-${hotelId}-${field}`]) {
          cellRefs.current[`hotel-${hotelId}-${field}`].focus();
        }
      }, 0);
    }
  };

  const handleHotelChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedHotel(selectedValue);
    setEditableValue(selectedValue);
  };

  const handlePricingStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setSelectedPricingStatus(selectedStatus);
    setEditableValue(selectedStatus);
  };

  const handleBlur = async (pricingId, field) => {
    // if (field !== "Top Rated") return;

    const updatedPricing = {
      ...pricingData.find((pricing) => pricing._id === pricingId),
    };
    console.log(field);
    let keyToUpdate;
    let valueToUpdate;

    if (field === "Top Rated") {
      if (updatedPricing[field] !== selectedHotel) {
        keyToUpdate = "topRated";
        valueToUpdate = selectedHotel;
      }
    } else if (field === "Pricing Status") {
      if (updatedPricing[field] !== selectedPricingStatus) {
        keyToUpdate = "pricingStatus"; // Use the appropriate key name for the API
        valueToUpdate = selectedPricingStatus;
      }
    }

    const payload = {
      [keyToUpdate]: valueToUpdate,
    };

    try {
      await axios.put(
        `${baseUrl}/api/pricing/update-senior-pricing/${pricingId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPricingData(
        pricingData.map((pricing) =>
          pricing._id === pricingId ? updatedPricing : pricing
        )
      );
      setEditingCell(null);
      fetchData();
    } catch (error) {
      console.error("Error updating cell:", error);
    }
  };

  const handleHotelBlur = async () => {
    if (editableValue === undefined || editableValue.trim() === "") {
      console.log("No changes made or Selling Price is empty.");
      setEditingHotel(null);
      return;
    }

    const { hotelId, pricingId } = editingHotel;
    const { field } = editingCell;
    console.log(field);

    const payload = {
      hotelId,
      [field]: editableValue,
    };

    try {
      await axios.put(
        `${baseUrl}/api/pricing/update-senior-pricing/${pricingId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPricingData((prevData) =>
        prevData.map((pricing) => {
          if (pricing._id === pricingId) {
            return {
              ...pricing,
              Hotels: pricing.Hotels.map((hotel) =>
                hotel._id === hotelId
                  ? { ...hotel, [field]: editableValue }
                  : hotel
              ),
            };
          }
          return pricing;
        })
      );

      setEditingHotel(null);
    } catch (error) {
      console.error("Error updating hotel selling price:", error);
    }
  };

  const handleApproveClick = async (pricingId) => {
    try {
      await axios.put(
        `${baseUrl}/api/pricing/approve-pricing/${pricingId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the local state after approval
      setPricingData((prevData) =>
        prevData.map((pricing) =>
          pricing._id === pricingId ? { ...pricing, isExpired: false } : pricing
        )
      );

      // Optionally refetch the data if needed
      fetchData();
    } catch (error) {
      console.error("Error updating isExpired:", error);
    }
  };

  const handleChange = (event) => {
    console.log(event.target.value);

    setEditableValue(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Toggle expanded row
  const handleShowHotelsClick = (pricingId) => {
    setExpandedRow(expandedRow === pricingId ? null : pricingId);
  };

  const canShowApproveButton = (pricing) => {
    if (!pricing.isExpired) return false;

    return (
      pricing.Hotels.every((hotel) => hotel["Average Nightly Rate with Markup"]) &&
      pricing["Pricing Status"] === "High"
    );
  };

  const handleHideClick = async (pricingId, hotelId, currentHiddenStatus) => {
    try {
      // Determine the new hidden status
      const newHiddenStatus = !currentHiddenStatus;

      // Make API request to update the hidden status
      const response = await axios.put(
        `${baseUrl}/api/pricing/pricing/${pricingId}/hotels/${hotelId}`,
        { hidden: newHiddenStatus }
      );

      // Log the response from the server
      fetchData();

      // Optionally, you can update the local state or UI based on the response here
    } catch (error) {
      console.error("Error updating hotel hidden status:", error);
    }
  };

  const handleCreateHotelClick = (pricingId) => {
    console.log(pricingId);

    setSelectedPricingId(pricingId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPricingId(null);
  };

  const handleHotelCreated = (newHotel) => {
    console.log(newHotel);

    const updatedPricingData = pricingData.map((pricing) => {
      if (pricing._id === selectedPricingId) {
        console.log(pricing);

        return {
          ...pricing,
          Hotels: Array.isArray(pricing.Hotels)
            ? [...pricing.Hotels, newHotel] // Add new hotel to the Hotels array
            : [newHotel], // If it's not an array, create one with the new hotel
        };
      }
      return pricing;
    });

    setPricingData(updatedPricingData); // Update state with the modified data
  };

  return (
    <div className="table-container">
      <table className="table-scrollable" style={{ maxHeight: "100px" }}>
        <thead>
          <tr>
            {columnsOfTable.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pricingData.map((pricing) => (
            <React.Fragment key={pricing._id}>
              <tr>
                {columnsOfTable.map((column) => (
                  <td
                    key={column.field}
                    onClick={() =>
                      column.field !== "Actions" &&
                      handleCellClick(
                        pricing._id,
                        column.field,
                        pricing[column.field] || ""
                      )
                    }
                  >
                    {column.field === "Actions" ? (
                      <button
                        onClick={() => handleShowHotelsClick(pricing._id)}
                      >
                        {expandedRow === pricing._id
                          ? "Hide Hotels"
                          : "Show Hotels"}
                      </button>
                    ) : column.field === "Approve" ? (
                      canShowApproveButton(pricing) ? (
                        <button onClick={() => handleApproveClick(pricing._id)}>
                          Approve
                        </button>
                      ) : (
                        "Not Eligible"
                      )
                    ) : editingCell?.pricingId === pricing._id &&
                      editingCell.field === column.field ? (
                      column.field === "Top Rated" ? (
                        <Select
                          value={selectedHotel}
                          onChange={handleHotelChange}
                          onBlur={() => handleBlur(pricing._id, column.field)}
                          sx={{ width: 200 }}
                        >
                          <MenuItem value="">Select a Hotel</MenuItem>
                          {pricing.Hotels.map((hotel) => (
                            <MenuItem
                              key={hotel._id}
                              value={hotel["Hotel Name"]}
                            >
                              {hotel["Hotel Name"]}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : column.field === "Pricing Status" ? (
                        <Select
                          value={selectedPricingStatus}
                          onChange={handlePricingStatusChange}
                          onBlur={() => handleBlur(pricing._id, column.field)}
                          sx={{ width: 200 }}
                        >
                          <MenuItem value="">Select Status</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                          <MenuItem value="Low">Low</MenuItem>
                        </Select>
                      ) : (
                        <TextField
                          ref={(el) =>
                            (cellRefs.current[
                              `${pricing._id}-${column.field}`
                            ] = el)
                          }
                          type="text"
                          value={editableValue}
                          onChange={(e) => setEditableValue(e.target.value)}
                          onBlur={() => handleBlur(pricing._id, column.field)}
                          variant="outlined"
                          size="small"
                        />
                      )
                    ) : column.field === "isExpired" ? (
                      pricing[column.field] ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : column.field === "lastModified" ? (
                      formatDate(pricing[column.field])
                    ) : typeof pricing[column.field] === "object" &&
                      pricing[column.field] !== null ? (
                      <div>
                        {Object.keys(pricing[column.field]).map((key) => (
                          <div key={key}>
                            {key}: {JSON.stringify(pricing[column.field][key])}
                          </div>
                        ))}
                      </div>
                    ) : (
                      pricing[column.field]
                    )}
                  </td>
                ))}
              </tr>
              {expandedRow === pricing._id && (
                <tr>
                  <td colSpan={columnsOfTable.length}>
                    <div className="accordion-content">
                      {/* Render additional details here */}
                      {pricing.Hotels && pricing.Hotels.length > 0 ? (
                        <div className="hotel-details">
                          <button
                            onClick={() => handleCreateHotelClick(pricing._id)}
                            style={{ marginBottom: "1rem" }}
                          >
                            Create Hotel
                          </button>
                          <table className="hotel-details-table">
                            <thead>
                              <tr>
                                <th>Actions</th>
                                <th>Hidden</th>
                                <th>Hotel Name</th>
                                <th>Stars</th>
                                <th>Image URL</th>
                                {/* <th>Selling Price</th>
                                <th>Online Price</th>
                                <th>Actual Price</th> */}
                                <th>Average Nightly Rate with Markup</th>
                                <th>Address</th>
                                <th>Distance</th>
                                <th>Amenities</th>
                                <th>Room Type</th>
                                <th>Free Cancellation Until</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pricing.Hotels.map((hotel) => (
                                <tr key={hotel._id}>
                                  <td>
                                    <Button
                                      onClick={() =>
                                        handleHideClick(
                                          pricing._id,
                                          hotel._id,
                                          hotel.hidden
                                        )
                                      }
                                      sx={{ color: "white" }}
                                    >
                                      {hotel.hidden ? "Show" : "Hide"}
                                    </Button>
                                  </td>
                                  <td>{hotel.hidden ? "Yes" : "No"}</td>
                                  <td>{hotel["Hotel Name"]}</td>
                                  <td>{hotel.Stars}</td>
                                  <td>
                                    <img
                                      src={hotel["Image URL"]}
                                      alt={hotel["Hotel Name"]}
                                      style={{ width: "100px" }}
                                    />
                                  </td>
                                  {/* <td
                                    onClick={() =>
                                      handleHotelCellClick(
                                        pricing._id,
                                        hotel._id,
                                        "SellingPrice",
                                        hotel["SellingPrice"]
                                      )
                                    }
                                  >
                                    {editingHotel?.hotelId === hotel._id &&
                                    editingHotel?.pricingId === pricing._id &&
                                    editingCell?.field === "SellingPrice" ? (
                                      <TextField
                                        ref={(el) =>
                                          (cellRefs.current[
                                            `hotel-${hotel._id}-SellingPrice`
                                          ] = el)
                                        }
                                        type="text"
                                        value={editableValue}
                                        onChange={(e) =>
                                          setEditableValue(e.target.value)
                                        }
                                        onBlur={handleHotelBlur}
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : (
                                      hotel["SellingPrice"]
                                    )}
                                  </td>

                                  <td
                                    onClick={() =>
                                      handleHotelCellClick(
                                        pricing._id,
                                        hotel._id,
                                        "Online Price",
                                        hotel["Online Price"]
                                      )
                                    }
                                  >
                                    {editingHotel?.hotelId === hotel._id &&
                                    editingHotel?.pricingId === pricing._id &&
                                    editingCell?.field === "Online Price" ? (
                                      <TextField
                                        ref={(el) =>
                                          (cellRefs.current[
                                            `hotel-${hotel._id}-Online Price`
                                          ] = el)
                                        }
                                        type="text"
                                        value={editableValue}
                                        onChange={(e) =>
                                          setEditableValue(e.target.value)
                                        }
                                        onBlur={handleHotelBlur}
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : (
                                      hotel["Online Price"]
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleHotelCellClick(
                                        pricing._id,
                                        hotel._id,
                                        "Actual Price",
                                        hotel["Actual Price"]
                                      )
                                    }
                                  >
                                    {editingHotel?.hotelId === hotel._id &&
                                    editingHotel?.pricingId === pricing._id &&
                                    editingCell?.field === "Actual Price" ? (
                                      <TextField
                                        ref={(el) =>
                                          (cellRefs.current[
                                            `hotel-${hotel._id}-Actual Price`
                                          ] = el)
                                        }
                                        type="text"
                                        value={editableValue}
                                        onChange={(e) =>
                                          setEditableValue(e.target.value)
                                        }
                                        onBlur={handleHotelBlur}
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : (
                                      hotel["Actual Price"]
                                    )}
                                  </td> */}
                                  <td>
                                    {hotel["Average Nightly Rate with Markup"]}
                                  </td>
                                  <td>{hotel.Address}</td>
                                  <td
                                    onClick={() =>
                                      handleHotelCellClick(
                                        pricing._id,
                                        hotel._id,
                                        "Distance",
                                        hotel.Distance
                                      )
                                    }
                                  >
                                    {editingHotel?.hotelId === hotel._id &&
                                    editingHotel?.pricingId === pricing._id &&
                                    editingCell?.field === "Distance" ? (
                                      <TextField
                                        ref={(el) =>
                                          (cellRefs.current[
                                            `hotel-${hotel._id}-Distance`
                                          ] = el)
                                        }
                                        type="text"
                                        value={editableValue}
                                        onChange={(e) =>
                                          setEditableValue(e.target.value)
                                        }
                                        onBlur={handleHotelBlur}
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : (
                                      hotel.Distance
                                    )}
                                  </td>
                                  <td>
                                    {hotel.Amenities ? (
                                      <table className="amenities-table">
                                        <thead>
                                          <tr>
                                            <th>Air Conditioned</th>
                                            <th>Gym</th>
                                            <th>Internet</th>
                                            <th>Parking</th>
                                            <th>24-Hour Reception</th>
                                            <th>Restaurant</th>
                                            <th>Spa</th>
                                            <th>Swimming Pool</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              {hotel.Amenities[0]
                                                ?.airConditioned
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.gym
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.internet
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.parking
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]
                                                ?.reception24Hour
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.restaurant
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.spa
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.swimmingPool
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    ) : (
                                      <p>No amenities available.</p>
                                    )}
                                  </td>
                                  <td>{hotel["Room Type"]}</td>
                                  <td>{hotel["Free Cancellation Until"]}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        "No hotel details available."
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <CreateHotelInMatchedPricing
          pricingId={selectedPricingId}
          token={token}
          onClose={handleModalClose}
          onHotelCreated={handleHotelCreated}
        />
      )}
    </div>
  );
};

export default PricingTable;
