import React, { useContext, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Modal,
  Box,
  Backdrop,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Settings } from "@mui/icons-material";
import { baseUrl } from "../../api/Api";
import axios from "axios";
import AccountSettings from "./AccountSettings";

const MainNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, token } = useContext(AuthContext);
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [breakTimer, setBreakTimer] = useState(0);
  const [totalBreakTime, setTotalBreakTime] = useState(0); // Track total break time
  const [isBreakTimerRunning, setIsBreakTimerRunning] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);

  useEffect(() => {
    // Initialize login timer
    const storedStartTime = localStorage.getItem("startTime");
    if (storedStartTime) {
      const elapsedTime = Date.now() - storedStartTime;
      setTimer(elapsedTime);
      setIsTimerRunning(true);
    } else {
      setIsTimerRunning(true);
      localStorage.setItem("startTime", Date.now());
    }

    // Initialize break timer
    const storedBreakStartTime = localStorage.getItem("breakStartTime");
    const storedBreakTimer = localStorage.getItem("breakTimer");
    if (storedBreakStartTime) {
      const elapsedBreakTime = Date.now() - storedBreakStartTime;
      setBreakTimer(
        (storedBreakTimer ? +storedBreakTimer : 0) + elapsedBreakTime
      );
      setIsBreakTimerRunning(true);
      setIsPopupVisible(true); // Show popup if break timer is running
    } else if (storedBreakTimer) {
      setBreakTimer(+storedBreakTimer); // Restore break timer even if it's not running
    }

    const interval = setInterval(() => {
      if (isTimerRunning) {
        setTimer((prevTime) => prevTime + 1000);
      }
      if (isBreakTimerRunning) {
        setBreakTimer((prevBreakTime) => prevBreakTime + 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isTimerRunning, isBreakTimerRunning]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  const startBreak = () => {
    if (!isBreakTimerRunning) {
      localStorage.setItem("breakStartTime", Date.now());
      setIsBreakTimerRunning(true);
      setIsPopupVisible(true); // Show popup on break start
      axios
        .post(
          `${baseUrl}/api/users/start-break`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send the token in the headers
            },
          }
        )
        .then((response) => {
          console.log("Break started:", response.data); // Handle success
        })
        .catch((error) => {
          console.error("Error starting break:", error); // Handle error
        });
    }
  };

  const endBreak = () => {
    if (isBreakTimerRunning) {
      const storedBreakStartTime = localStorage.getItem("breakStartTime");
      if (storedBreakStartTime) {
        const elapsedBreakTime = Date.now() - storedBreakStartTime;
        const totalBreakTime = breakTimer;
        setBreakTimer(totalBreakTime);
        setTotalBreakTime(totalBreakTime); // Set total break time
        localStorage.setItem("breakTimer", totalBreakTime);
      }
      localStorage.removeItem("breakStartTime");
      axios
        .post(
          "http://localhost:4800/api/users/end-break", // API endpoint
          {}, // Empty payload
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send the token in the headers
            },
          }
        )
        .then((response) => {
          console.log("Break ended:", response.data); // Handle success
        })
        .catch((error) => {
          console.error("Error ending break:", error); // Handle error
        });
      setIsBreakTimerRunning(false);
      setIsPopupVisible(false); // Hide popup after break ends
    }
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    let idleTimeout;

    const resetIdleTimer = () => {
      clearTimeout(idleTimeout);
      idleTimeout = setTimeout(() => {
        // Trigger break if idle for 1 minute
        startBreak();
      }, 300000); // 1 minute of inactivity
    };

    // Attach event listeners for user actions
    const events = ["mousemove", "keydown", "click"];
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    // Start the idle timer on first render
    resetIdleTimer();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(idleTimeout);
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTimer)
      );
    };
  }, []);

  const handleToggle = () => {
    setIsSettingsVisible((prev) => !prev);
  };

  return (
    <AppBar position="static" className="update-nav">
      <Toolbar style={{ paddingLeft: "4rem", paddingRight: "2rem" }}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <h4 style={{ textTransform: "uppercase" }}>SkyLine Housing</h4>
        </Typography>
        {location.pathname !== "/" && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="timer-container">
              <span className="timer-label">Login Time</span>
              <p className="timer">{formatTime(timer)}</p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                onClick={startBreak}
                disabled={isBreakTimerRunning}
                className="start-break-button"
              >
                Start Break
              </button>
            </div>
            {/* <Button
              color="inherit"
              onClick={handleLogout}
              style={{ width: "160px", padding: "8px", borderRadius: "8px" }}
            >
              Logout
            </Button> */}
            <AccountSettings handleLogout={handleLogout} />
          </div>
        )}

        <Modal
          open={isPopupVisible}
          onClose={() => {}}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            onClick: (e) => e.stopPropagation(), // Disable backdrop click
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Break Time</Typography>
            <p className="timer">{formatTime(breakTimer)}</p>
            <Button
              variant="contained"
              color="primary"
              onClick={endBreak}
              style={{ marginTop: "20px" }}
            >
              End Break
            </Button>
          </Box>
        </Modal>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavBar;
