import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import "./JuniorPricingTable.css";
import { AuthContext } from "../../context/AuthContext";
import UploadHotels from "./UploadHotels";
import CreateHotelModal from "./CreateHotelModal";
import { baseUrl } from "../../../api/Api";
import CreateRawPricingModal from "./CreateRawPricingModal";

const JuniorPricingTable = ({ data }) => {
  const [editingCell, setEditingCell] = useState(null);
  const [pricingData, setPricingData] = useState(data || []);
  const [editableValue, setEditableValue] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [isCreatePricingModal, setIsCreatePricingModal] = useState(false);
  const [selectedPricingId, setSelectedPricingId] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const { token, role } = useContext(AuthContext);
  const cellRefs = useRef({});

  useEffect(() => {
    setPricingData(data || []);
  }, [data]);
  console.log(pricingData);

  const allColumns = [
    { field: "Controllers", headerName: "Controllers" },
    { field: "showName", headerName: "Show Name" },
    { field: "isExpired", headerName: "Expired" },
    { field: "lastModified", headerName: "Date Modified" },
  ];

  const columnsOfTable =
    role !== "pricing junior"
      ? allColumns
      : allColumns.filter((column) => column.field !== "SellingPrice");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleToggleHotels = (event, pricingId) => {
    event.stopPropagation();
    setExpandedRow(expandedRow === pricingId ? null : pricingId);
  };

  const handleCreateHotelClick = (pricingId) => {
    setSelectedPricingId(pricingId);
    setIsModalOpen(true);
    setModalMode("");
  };

  const handleCreatePricingClick = () => {
    setIsCreatePricingModal(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsCreatePricingModal(false);
    setSelectedPricingId(null);
  };

  const handleHotelCreated = (newHotel) => {
    console.log(newHotel);

    const updatedPricingData = pricingData.map((pricing) => {
      if (pricing._id === selectedPricingId) {
        console.log(pricing);

        return {
          ...pricing,
          Hotels: Array.isArray(pricing.Hotels)
            ? [...pricing.Hotels, newHotel]
            : [newHotel],
        };
      }
      return pricing;
    });

    setPricingData(updatedPricingData);
  };

  const handleEditHotel = (updatedHotel) => {
    console.log(updatedHotel);

    const updatedPricingData = pricingData.map((pricing) => {
      if (pricing._id === selectedPricingId) {
        console.log(pricing);

        return {
          ...pricing,
          Hotels: Array.isArray(pricing.Hotels)
            ? pricing.Hotels.map((hotel) =>
                hotel._id === updatedHotel._id
                  ? { ...hotel, ...updatedHotel }
                  : hotel
              )
            : [],
        };
      }
      return pricing;
    });

    setPricingData(updatedPricingData); // Update state with the modified data
  };

  const handleEditPricingClick = (pricingId, hotel) => {
    setModalMode("edit");
    setSelectedPricingId(pricingId);
    setSelectedHotel(hotel);
    setIsModalOpen(true);
  };

  return (
    <div className="table-container">
      <button
        onClick={() => handleCreatePricingClick()}
        style={{ width: "10%", marginBottom: "1rem" }}
      >
        Create Pricing
      </button>
      <table className="table-scrollable">
        <thead>
          <tr>
            {columnsOfTable.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pricingData.map((pricing) => (
            <React.Fragment key={pricing._id}>
              <tr style={{ cursor: "pointer" }}>
                {columnsOfTable.map((column) => (
                  <td key={column.field}>
                    {column.field === "Controllers" ? (
                      <div>
                        <button
                          onClick={(event) =>
                            handleToggleHotels(event, pricing._id)
                          }
                        >
                          Toggle Hotels
                        </button>
                      </div>
                    ) : editingCell?.pricingId === pricing._id &&
                      editingCell.field === column.field ? (
                      <input
                        ref={(el) =>
                          (cellRefs.current[`${pricing._id}-${column.field}`] =
                            el)
                        }
                        type="text"
                        value={editableValue}
                      />
                    ) : column.field === "Shows" ? (
                      pricing[column.field] &&
                      Array.isArray(pricing[column.field]) ? (
                        pricing[column.field].map((show) => (
                          <div key={show._id}>{show.Show}</div>
                        ))
                      ) : (
                        <div>No Shows</div>
                      )
                    ) : column.field === "isExpired" ? (
                      pricing[column.field] ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : column.field === "lastModified" ? (
                      formatDate(pricing[column.field])
                    ) : typeof pricing[column.field] === "object" &&
                      pricing[column.field] !== null ? (
                      <div>
                        {Object.keys(pricing[column.field]).map((key) => (
                          <div key={key}>
                            {key}: {JSON.stringify(pricing[column.field][key])}
                          </div>
                        ))}
                      </div>
                    ) : (
                      pricing[column.field]
                    )}
                  </td>
                ))}
              </tr>
              {expandedRow === pricing._id && (
                <tr>
                  <td colSpan={columnsOfTable.length}>
                    <div>
                      <div className="table-controllers">
                        <button
                          onClick={() => handleCreateHotelClick(pricing._id)}
                        >
                          Create Hotel
                        </button>
                      </div>
                      <div className="accordion-content">
                        {pricing.Hotels && pricing.Hotels.length > 0 ? (
                          <table className="hotel-details-table">
                            <thead>
                              <tr>
                                <th>Controllers</th>
                                <th>Hotel Name</th>
                                <th>Stars</th>
                                <th>Image URL</th>
                                <th>Nightly Rate</th>
                                <th>Address</th>
                                <th>Amenities</th>
                                <th>Room Type</th>
                                <th>Free Cancellation Until</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pricing.Hotels.map((hotel) => (
                                <tr key={hotel._id} className="hotel-details">
                                  <td>
                                    <button
                                      onClick={() =>
                                        handleEditPricingClick(
                                          pricing._id,
                                          hotel
                                        )
                                      }
                                    >
                                      Edit
                                    </button>
                                  </td>
                                  <td>{hotel["Hotel Name"]}</td>
                                  <td>{hotel.Stars}</td>
                                  <td>
                                    <img
                                      src={hotel["Image URL"]}
                                      alt={hotel["Hotel Name"]}
                                      style={{ width: "100px" }}
                                    />
                                  </td>
                                  <td>
                                    {hotel["Average Nightly Rate with Markup"]}
                                  </td>
                                  <td>{hotel.Address}</td>
                                  <td>
                                    {hotel.Amenities ? (
                                      <table className="amenities-table">
                                        <thead>
                                          <tr>
                                            <th>Air Conditioned</th>
                                            <th>Gym</th>
                                            <th>Internet</th>
                                            <th>Parking</th>
                                            <th>24-Hour Reception</th>
                                            <th>Restaurant</th>
                                            <th>Spa</th>
                                            <th>Swimming Pool</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              {hotel.Amenities[0]
                                                ?.airConditioned
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.gym
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.internet
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.parking
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]
                                                ?.reception24Hour
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.restaurant
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.spa
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.swimmingPool
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    ) : (
                                      <p>No amenities available.</p>
                                    )}
                                  </td>
                                  <td>{hotel["Room Type"]}</td>
                                  <td>{hotel["Free Cancellation Until"]}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <p>No hotel data available.</p>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <CreateHotelModal
          pricingId={selectedPricingId}
          token={token}
          onClose={handleModalClose}
          onHotelCreated={handleHotelCreated}
          onHotelEdited={handleEditHotel}
          mode={modalMode}
          selectedHotel={selectedHotel}
        />
      )}
      {isCreatePricingModal && (
        <CreateRawPricingModal
          token={token}
          onClose={handleModalClose}
          onPricingCreated={(newPricing) => {
            setPricingData((prevData) => [...prevData, newPricing]);
          }}
        />
      )}
    </div>
  );
};

export default JuniorPricingTable;
