import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";

import { TextField, MenuItem, Select } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { baseUrl } from "../../api/Api";

const HotelsPopup = ({ data, showType }) => {
  const [editingHotel, setEditingHotel] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [pricingData, setPricingData] = useState([data] || []);

  const [expandedRow, setExpandedRow] = useState(null);
  const [editableValue, setEditableValue] = useState("");
  const [selectedHotel, setSelectedHotel] = useState("");
  const [selectedPricingStatus, setSelectedPricingStatus] = useState("");

  const { token } = useContext(AuthContext);
  const cellRefs = useRef({});

  const allColumns = [
    { field: "Actions", headerName: "Actions" },
    { field: "showName", headerName: "Show" },
    { field: "Pricing Status", headerName: "Pricing Status" },
    { field: "MadeBy", headerName: "Made By" },
    { field: "Min Budget", headerName: "Min Budget" },
    { field: "Min Distance", headerName: "Min Distance" },
    { field: "Min +- Max", headerName: "Min ± Max" },
    { field: "Top Rated", headerName: "Top Rated" },
    { field: "isExpired", headerName: "Expired" },
    { field: "lastModified", headerName: "Date Modified" },
  ];

  const handleCellClick = (pricingId, field, value) => {
    if (
      field !== "showName" &&
      field !== "Min Distance" &&
      field !== "Min +- Max" &&
      field !== "Min Budget" &&
      field !== "MadeBy" &&
      field !== "isExpired" &&
      field !== "lastModified"
    ) {
      setEditingCell({ pricingId, field });
      setEditableValue(value);
    }
  };
  const handleHotelCellClick = (pricingId, hotelId, field, value) => {
    console.log(field);

    if (field === "Online Price" || field === "Actual Price") {
      setEditingHotel({ hotelId, pricingId });
      setEditingCell({ hotelId, field });
      setEditableValue(value);
      setTimeout(() => {
        if (cellRefs.current[`hotel-${hotelId}-${field}`]) {
          cellRefs.current[`hotel-${hotelId}-${field}`].focus();
        }
      }, 0);
    }
  };

  const handlePricingStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setSelectedPricingStatus(selectedStatus);
    setEditableValue(selectedStatus);
  };

  const handleBlur = async (pricingId, field) => {
    if (field !== "Pricing Status") return;

    const updatedPricing = {
      ...pricingData.find((pricing) => pricing._id === pricingId),
    };

    updatedPricing[field] = selectedPricingStatus;

    const payload = {
      "Pricing Status": selectedPricingStatus,
    };
    let url;
    if (showType === "generated") {
      url = `${baseUrl}/api/locator/update-generated-pricing/${pricingId}`;
    } else {
      url = `${baseUrl}/api/locator/update-pricing/${pricingId}`;
    }
    try {
      await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPricingData(
        pricingData.map((pricing) =>
          pricing._id === pricingId ? updatedPricing : pricing
        )
      );
      setEditingCell(null);
      //   fetchData();
    } catch (error) {
      console.error("Error updating cell:", error);
    }
  };

  const handleHotelBlur = async () => {
    if (editableValue === undefined || editableValue.trim() === "") {
      console.log("No changes made or editable value is empty.");
      setEditingHotel(null);
      return;
    }

    const { hotelId, pricingId } = editingHotel;
    const field = editingCell?.field;

    // Ensure field and editableValue are valid
    if (!field || !editableValue) {
      console.log("Field or editable value is missing.");
      return;
    }

    const payload = {
      hotelId,
      [field]: editableValue,
    };

    let url;
    if (showType === "generated") {
      url = `${baseUrl}/api/locator/update-generated-pricing/${pricingId}`;
    } else {
      url = `${baseUrl}/api/locator/update-pricing/${pricingId}`;
    }
    try {
      // Make the API call
      await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Debug logging
      console.log(`Updating field: ${field} with value: ${editableValue}`);

      // Update the pricingData state with the new value
      setPricingData((prevData) =>
        prevData.map((pricing) => {
          if (pricing._id === pricingId) {
            console.log(pricing);
            console.log(hotelId);

            return {
              ...pricing,
              Hotels: pricing.Hotels.map((hotel) =>
                hotel._id === hotelId
                  ? { ...hotel, [field]: editableValue }
                  : hotel
              ),
            };
          }
          return pricing;
        })
      );

      // Clear the editing state
      setEditingHotel(null);
    } catch (error) {
      console.error("Error updating hotel selling price:", error);
    }
  };

  const handleChange = (event) => {
    console.log(event.target.value);

    setEditableValue(event.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Toggle expanded row
  const handleShowHotelsClick = (pricingId) => {
    setExpandedRow(expandedRow === pricingId ? null : pricingId);
  };

  console.log(pricingData);

  return (
    <div className="table-container">
      <table className="table-scrollable" style={{ maxHeight: "100px" }}>
        <thead>
          <tr>
            {allColumns.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pricingData.map((pricing) => (
            <React.Fragment key={pricing._id}>
              <tr>
                {allColumns.map((column) => (
                  <td
                    key={column.field}
                    onClick={() =>
                      column.field !== "Actions" &&
                      handleCellClick(
                        pricing._id,
                        column.field,
                        pricing[column.field] || ""
                      )
                    }
                  >
                    {column.field === "Actions" ? (
                      <button
                        onClick={() => handleShowHotelsClick(pricing._id)}
                      >
                        {expandedRow === pricing._id
                          ? "Hide Hotels"
                          : "Show Hotels"}
                      </button>
                    ) : editingCell?.pricingId === pricing._id &&
                      editingCell.field === column.field ? (
                      column.field === "Pricing Status" ? (
                        <Select
                          value={selectedPricingStatus}
                          onChange={handlePricingStatusChange}
                          onBlur={() => handleBlur(pricing._id, column.field)}
                          sx={{ width: 200 }}
                        >
                          <MenuItem value="">Select Status</MenuItem>
                          <MenuItem value="High">High</MenuItem>
                          <MenuItem value="Low">Low</MenuItem>
                        </Select>
                      ) : (
                        <TextField
                          ref={(el) =>
                            (cellRefs.current[
                              `${pricing._id}-${column.field}`
                            ] = el)
                          }
                          type="text"
                          value={editableValue}
                          onChange={(e) => setEditableValue(e.target.value)}
                          onBlur={() => handleBlur(pricing._id, column.field)}
                          variant="outlined"
                          size="small"
                        />
                      )
                    ) : column.field === "isExpired" ? (
                      pricing[column.field] ? (
                        "Yes"
                      ) : (
                        "No"
                      )
                    ) : column.field === "lastModified" ? (
                      formatDate(pricing[column.field])
                    ) : typeof pricing[column.field] === "object" &&
                      pricing[column.field] !== null ? (
                      <div>
                        {Object.keys(pricing[column.field]).map((key) => (
                          <div key={key}>
                            {key}: {JSON.stringify(pricing[column.field][key])}
                          </div>
                        ))}
                      </div>
                    ) : (
                      pricing[column.field]
                    )}
                  </td>
                ))}
              </tr>

              {expandedRow === pricing._id && (
                <tr>
                  <td colSpan={allColumns.length}>
                    <div className="accordion-content">
                      {pricing.Hotels && pricing.Hotels.length > 0 ? (
                        <div className="hotel-details">
                          <table className="hotel-details-table">
                            <thead>
                              <tr>
                                <th>Hotel Name</th>
                                <th>Stars</th>
                                <th>Image URL</th>
                                <th>Selling Price</th>
                                <th>Online Price</th>
                                <th>Actual Price</th>
                                <th>Address</th>
                                <th>Distance</th>
                                <th>Amenities</th>
                                <th>Room Type</th>
                                <th>Free Cancellation Until</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pricing.Hotels.map((hotel) => (
                                <tr key={hotel._id}>
                                  <td>{hotel["Hotel Name"]}</td>
                                  <td>{hotel.Stars}</td>
                                  <td>
                                    <img
                                      src={hotel["Image URL"]}
                                      alt={hotel["Hotel Name"]}
                                      style={{ width: "100px" }}
                                    />
                                  </td>
                                  <td>
                                    {hotel["Average Nightly Rate with Markup"]}
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleHotelCellClick(
                                        pricing._id,
                                        hotel._id,
                                        "Online Price",
                                        hotel["Online Price"]
                                      )
                                    }
                                  >
                                    {editingHotel?.hotelId === hotel._id &&
                                    editingHotel?.pricingId === pricing._id &&
                                    editingCell?.field === "Online Price" ? (
                                      <TextField
                                        ref={(el) =>
                                          (cellRefs.current[
                                            `hotel-${hotel._id}-Online Price`
                                          ] = el)
                                        }
                                        type="text"
                                        value={editableValue}
                                        onChange={(e) =>
                                          setEditableValue(e.target.value)
                                        }
                                        onBlur={handleHotelBlur}
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : (
                                      hotel["Online Price"]
                                    )}
                                  </td>
                                  <td
                                    onClick={() =>
                                      handleHotelCellClick(
                                        pricing._id,
                                        hotel._id,
                                        "Actual Price",
                                        hotel["Actual Price"]
                                      )
                                    }
                                  >
                                    {editingHotel?.hotelId === hotel._id &&
                                    editingHotel?.pricingId === pricing._id &&
                                    editingCell?.field === "Actual Price" ? (
                                      <TextField
                                        ref={(el) =>
                                          (cellRefs.current[
                                            `hotel-${hotel._id}-Actual Price`
                                          ] = el)
                                        }
                                        type="text"
                                        value={editableValue}
                                        onChange={(e) =>
                                          setEditableValue(e.target.value)
                                        }
                                        onBlur={handleHotelBlur}
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : (
                                      hotel["Actual Price"]
                                    )}
                                  </td>
                                  <td>{hotel.Address}</td>
                                  <td>{hotel.Distance}</td>
                                  <td>
                                    {hotel.Amenities ? (
                                      <table className="amenities-table">
                                        <thead>
                                          <tr>
                                            <th>Air Conditioned</th>
                                            <th>Gym</th>
                                            <th>Internet</th>
                                            <th>Parking</th>
                                            <th>24-Hour Reception</th>
                                            <th>Restaurant</th>
                                            <th>Spa</th>
                                            <th>Swimming Pool</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              {hotel.Amenities[0]
                                                ?.airConditioned
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.gym
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.internet
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.parking
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]
                                                ?.reception24Hour
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.restaurant
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.spa
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>
                                              {hotel.Amenities[0]?.swimmingPool
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    ) : (
                                      <p>No amenities available.</p>
                                    )}
                                  </td>

                                  <td>{hotel["Room Type"]}</td>
                                  <td>{hotel["Free Cancellation Until"]}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        "No hotel details available."
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HotelsPopup;
