import React, { useState, useEffect, useCallback, useContext } from "react";
import { AuthContext } from "../../components/context/AuthContext";
import "./AgentCloser.css";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import CloserTab from "../../components/CloserTab/CloserTab";

const statusOptions = [
  { label: "New", value: "New" },
  { label: "VM", value: "VM" },
  { label: "Callback", value: "Callback" },
  { label: "GK Blocked", value: "GK Blocked" },
  { label: "DM Rejected", value: "DM Rejected" },
  { label: "Do Not Call", value: "Do Not Call" },
  { label: "Email Only", value: "Email Only" },
  { label: "Email Follow-up", value: "Email Follow-up" },
  { label: "Waiting Approval", value: "Waiting Approval" },
  { label: "Prospect", value: "Prospect" },
  { label: "Flagged", value: "Flagged" },
  { label: "N/A", value: "N/A" },
  { label: "Closed Won", value: "Closed Won" },
  { label: "Closed Lost", value: "Closed Lost" },
];

const AgentCloser = () => {
  const { role, token } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isNewActive, setIsNewActive] = useState(true);
  const [isHistoryActive, setIsHistoryActive] = useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOverlayOpen, setDialogOverlayOpen] = useState(false);

  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");

  console.log("selectedLead",selectedLead);
  

  const toggleSidebar = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const showNewContent = useCallback(() => {
    setIsNewActive(true);
    setIsHistoryActive(false);
  }, []);

  const showHistoryContent = useCallback(() => {
    setIsNewActive(false);
    setIsHistoryActive(true);
  }, []);

  // Fetch leads data from the API
  useEffect(() => {
    const fetchLeadsData = async () => {
      try {
        const response = await axios.get(
          "http://localhost:4800/api/closers/leads-to-close",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.length > 0) {
          const allLeads = response.data.flatMap((item) => item.leads);
          setLeadsData(allLeads);
        }
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch leads data");
        setLoading(false);
      }
    };

    if (token) {
      fetchLeadsData();
    } else {
      setError("No token provided");
      setLoading(false);
    }
  }, [token]);

  const handleAnswerClick = (lead) => {
    setSelectedLead(lead);
    setDialogOpen(true);
    setSelectedStatus(lead.Status); 
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedLead(null);
    setSelectedStatus("");
  };

  // Open the dialog
  const handleOpenOverlayDialog = () => {
    setDialogOverlayOpen(true);
  };

  // Close the dialog
  const handleCloseOverlayDialog = () => {
    setDialogOverlayOpen(false);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedLead((prevLead) => ({
      ...prevLead,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    // Ensure the status is selected and a lead is chosen
    if (!selectedStatus || !selectedLead) {
      alert('Please select a status and a lead');
      return;
    }

    const { showId,  _id } = selectedLead;

     // Prepare the PUT request payload

    const payload = {
      "Max. Distance": selectedLead[" Distance"],
      "/ Room Stay": selectedLead["/ Room Stay"],
      Activities: selectedLead.Activities,
      Budget: selectedLead.Budget,
      // "Closer Assigned": selectedLead["Closer Assigned"],
      "Closer Note": selectedLead["Closer Note"],
      "Closing Date": selectedLead["Closing Date"],
      Lead: selectedLead.Company,
      "DM Name": selectedLead["Contact Name"],
      "DM Rating": selectedLead["DM Rating"],
      "Days to Call": selectedLead["Days to Call"],
      Email: selectedLead.Email,
      "Email Type": selectedLead["Email Type"],
      "Email Notes": selectedLead["Email Notes"],
      "End Date":selectedLead["End Date"],
      "Check-out": selectedLead["Check-out"],
      Hotel: selectedLead.Hotel,
      "Last Contact": selectedLead["Last Contact"],
      "Next Contact": selectedLead["Next Contact"],
      "Next Dates":selectedLead["Next Dates"],
      "Check-in": selectedLead["Check-in"],
      Notes: selectedLead.Notes,
      roomDetails: selectedLead.roomDetails,
      "Rejection Reason": selectedLead["Rejection Reason"],
      Rooms: selectedLead.Rooms,
      Status: selectedStatus,
      Timezone: selectedLead.Timezone,
      "Total Stay": selectedLead["Total Stay"]      
    
    };

    try {
      const response = await axios.put(
        `http://localhost:4800/api/closers/shows/${showId}/companies/${_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {

        // Update the leadsData state with the modified lead
        setLeadsData((prevLeads) =>
          prevLeads.map((lead) =>
            lead._id === _id ? { ...lead, Status: selectedStatus } : lead
          )
        );

        setDialogOverlayOpen(false); // Close the overlay dialog
        setDialogOpen(false); // Close the main dialog
        setSelectedLead(null); // Clear the selected lead
        setSelectedStatus(""); // Clear the selected status
      } else {
        alert('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
      alert('An error occurred while updating the status');
    }
  };

  return (
    <div className="">
      <div className="agent-contain">
        <div className="sidebar-container">
          {isOpen ? (
            <div className="menu-bar-open">
              <MdOutlineClose className="menu-icon" onClick={toggleSidebar} />
              <button onClick={showNewContent} className="sidebar-btn">
                New Leads
              </button>
              {/* <button onClick={showHistoryContent} className="sidebar-btn">
                History
              </button> */}
            </div>
          ) : (
            <div className="menu-bar-close">
              <HiOutlineMenuAlt2
                className="menu-icon"
                onClick={toggleSidebar}
              />
            </div>
          )}
        </div>
        {/* ///////////////////////////////////////////////////// */}
        <div className="main-agent-container">
          <div className="main-agent-contain">
            <div className="main-header-info">
              <h2 className="main-header-h2">Agent Closer View</h2>
            </div>
            <div className="main-header-container">
              {isNewActive && (
                <div className="main-agent-closer-container">
                  {loading ? (
                    <div>Loading...</div>
                  ) : error ? (
                    <div>{error}</div>
                  ) : (
                    <TableContainer
                      component={Paper}
                      sx={{
                        maxHeight: "75vh",
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow
                            sx={{
                              background:
                                "linear-gradient(45deg, #8b5a5a, #6c4c6e, #4a6880)",
                            }}
                          >
                            {[
                              "Lead Name",
                              "Show Name",
                              "City, State",
                              "Timezone",
                              "Phone",
                              "Status",
                              "Answer",
                            ].map((header, index) => (
                              <TableCell
                                key={header}
                                sx={{
                                  color: "#fff",
                                  borderRight: "2px solid #fff",
                                  fontSize: "17px",
                                  fontFamily: '"Playfair Display", serif',
                                  letterSpacing: 1,
                                }}
                              >
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {leadsData.map((lead) => (
                            <TableRow key={lead._id}>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "4px 10px",
                                }}
                              >
                                {lead.Lead}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "4px 10px",
                                }}
                              >
                                {lead.Show}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "4px 10px",
                                }}
                              >
                                {lead.cityState}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "4px 10px",
                                }}
                              >
                                {lead.Timezone}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "4px 10px",
                                }}
                              >
                                {lead.Phone}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "4px 10px",
                                }}
                              >
                                {lead.Status}
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderRight: "2px solid #e0e0e0",
                                  fontSize: "14px",
                                  fontWeight: 550,
                                  whiteSpace: "nowrap",
                                  wordBreak: "keep-all",
                                  padding: "4px 10px",
                                }}
                              >
                                <button
                                  className="agent-closer-btn"
                                  onClick={() => handleAnswerClick(lead)}
                                >
                                  Answer
                                </button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              )}
              {isHistoryActive && (
                <div className="main-agent-closer-container">Hisotry Data</div>
              )}
            </div>
          </div>
          {/* Dialog Component */}
          <Dialog open={dialogOpen} fullScreen>
            <div className="info-agent-closer">
              {selectedLead && (
                <div className="dialog-closer-contain">
                  <div style={{ marginBottom: "16px" }}>
                    <p
                      style={{
                        fontSize: "19px",
                        marginBottom: "2px",
                        fontWeight: "700",
                      }}
                    >
                      <strong
                        style={{ color: "#4a6880", letterSpacing: "2px" }}
                      >
                        Lead Name:
                      </strong>
                      {selectedLead.Lead}
                    </p>
                    <p
                      style={{
                        fontSize: "19px",
                        marginBottom: "2px",
                        fontWeight: "700",
                      }}
                    >
                      <strong
                        style={{ color: "#4a6880", letterSpacing: "2px" }}
                      >
                        Show Name:
                      </strong>
                      {selectedLead.Show}
                    </p>
                    <p
                      style={{
                        fontSize: "19px",
                        marginBottom: "2px",
                        fontWeight: "700",
                      }}
                    >
                      <strong
                        style={{ color: "#4a6880", letterSpacing: "2px" }}
                      >
                        Status:
                      </strong>
                      {selectedLead.Status}
                    </p>
                    <div className="text-dialog-contain">
                      <CloserTab selectedLead={selectedLead} handleInputChange={handleInputChange}/>
                    </div>
                  </div>

                  <div className="bottom-button-dialog">
                    <button onClick={handleOpenOverlayDialog}>Save</button>
                  </div>
                </div>
              )}
            </div>
          </Dialog>
          <Dialog open={dialogOverlayOpen}>
            <DialogTitle>Select Status</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSave} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default AgentCloser;
