import React, { useContext, useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../api/Api";
import { AuthContext } from "../context/AuthContext";
import "./HouseLeadsTable.css";

const HouseLeadsTable = ({ activeShows }) => {
  const [selectedShowId, setSelectedShowId] = useState("");
  const [houseLeads, setHouseLeads] = useState([]);
  const [showPricing, setShowPricing] = useState([]);
  const { token } = useContext(AuthContext);

  const fetchHouseLeads = async (showId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/house/get-house-leads/${showId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setHouseLeads(response.data); // Save fetched leads to state
    } catch (error) {
      console.error("Error fetching house leads:", error);
    }
  };

  const fetchShowPricing = async (showId) => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/house/get-show-pricings/${showId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowPricing(response.data);
    } catch (error) {
      console.error("Error fetching house leads:", error);
    }
  };

  const updateLeadStatus = async (leadId, status) => {
    try {
      await axios.put(
        `${baseUrl}/api/house/update-status/${leadId}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Update the lead's status in the table without refetching
      setHouseLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead._id === leadId ? { ...lead, Status: status } : lead
        )
      );
    } catch (error) {
      console.error(`Error updating status to ${status}:`, error);
    }
  };

  const handleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedShowId(selectedId);
    fetchHouseLeads(selectedId);
    fetchShowPricing(selectedId);
  };
  console.log(houseLeads);

  const handleSendAll = async () => {
    try {
      const payload = {
        to: houseLeads.map((lead) => ({
          id: lead._id, // Include lead._id
          email: lead.Email, // Include lead.Email
        })),  
        subject: "Hotel Offers",
        text: "Check out the latest hotel offers.",
        templateId: "67053ae620f29a5175ad2270",
        hotelData: showPricing.Hotels.map((hotel) => {
          const amenitiesObject = hotel.Amenities[0] || {};
          const amenities = Object.keys(amenitiesObject)
            .filter((key) => amenitiesObject[key] === true)
            .map((key) => formatAmenityKey(key));

          return {
            name: hotel["Hotel Name"],
            stars: hotel.Stars,
            amenities,
            originalPrice: hotel["Online Price"],
            discountPrice: hotel["Average Nightly Rate with Markup"],
            image: hotel["Image URL"] || "",
            link: hotel.link || "",
            id: hotel._id,
          };
        }),
      };

      console.log("Payload:", payload);

      // Send the request with Axios
      const response = await axios.post(
        `${baseUrl}/api/house/send-email`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Emails sent successfully!");
    } catch (error) {
      console.error(
        "Error sending emails:",
        error.response?.data || error.message
      );
      alert("Failed to send emails. Please try again.");
    }
  };

  // Helper function to format amenity keys (e.g., capitalize the first letter)
  const formatAmenityKey = (key) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
  };

  return (
    <div style={{ padding: "1rem" }}>
      {/* Dropdown to select the show */}
      <FormControl style={{ width: "25%" }} variant="outlined">
        <InputLabel id="show-select-label">Select Show</InputLabel>
        <Select
          labelId="show-select-label"
          value={selectedShowId}
          onChange={handleChange}
          label="Select Show"
        >
          {activeShows.map((show) => (
            <MenuItem key={show._id} value={show._id}>
              {show.Show}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ margin: "20px 0" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={houseLeads.length === 0 || showPricing.length === 0}
          onClick={handleSendAll}
        >
          Send All
        </Button>
      </div>

      {/* Table to display leads */}
      {houseLeads.length > 0 ? (
        <Paper style={{ marginTop: "20px", overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lead</TableCell>
                <TableCell>DM Name</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Email</TableCell>
                <TableCell sx={{ width: "150px" }}>Status</TableCell>
                <TableCell>Actions</TableCell> {/* New column for buttons */}
              </TableRow>
            </TableHead>
            <TableBody>
              {houseLeads.map((lead) => (
                <TableRow key={lead._id}>
                  <TableCell>{lead.Lead}</TableCell>
                  <TableCell>{lead["DM Name"] || "N/A"}</TableCell>
                  <TableCell>{lead.Title || "N/A"}</TableCell>
                  <TableCell>{lead.Email}</TableCell>
                  <TableCell
                    className={`status ${lead.Status.toLowerCase()}`}
                    sx={{ width: "150px" }}
                  >
                    {lead.Status}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => updateLeadStatus(lead._id, "Sent")}
                      style={{ marginRight: "0.5rem" }}
                    >
                      Sent
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => updateLeadStatus(lead._id, "Error")}
                    >
                      Error
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        selectedShowId && <p>No leads found for this show.</p>
      )}
    </div>
  );
};

export default HouseLeadsTable;
